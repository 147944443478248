export function capitalizeStrings(
  inputString: string,
  separator: string = " ",
) {
  if (!inputString) return "";
  return inputString
    .trim() // remove leading or trailing spaces
    .split(separator)
    .filter((str) => str) // remove empty strings
    .map((str) => str.charAt(0).toUpperCase() + str.substring(1))
    .join(" ");
}
