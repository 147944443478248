import { makeStyles } from '@mui/styles';
import { COLORS, FONT } from '@kargo/shared-components.krg-shared';

const useBaseStyles = makeStyles({
  root: {
    fontSize: FONT.SIZE.BASE,
  },

  circularProgress: {
    position: 'absolute',
    right: '0.5em',
  },

  textFieldDisabled: {
    '& .MuiInput-underline:before': {
      borderBottomColor: COLORS.GRAY_DIVIDER, // Semi-transparent underline
      borderBottomStyle: 'solid',
    },
    '& svg': {
      color: COLORS.BLACK_INACTIVE,
    },
  },
});

export default useBaseStyles;
