import { SidebarState, SidebarActions, SidebarActionEnum } from "../../@types/sidebar.types";

export default (state: SidebarState, action: SidebarActions ): SidebarState => {
  switch (action.type) {
    case SidebarActionEnum.SET_MAIN_SIDEBAR:
      return { ...state, singlePageSidebar: [] };
    case SidebarActionEnum.SET_SINGLE_PAGE_SIDEBAR:
      return { ...state, singlePageSidebar: action.payload.singlePageSidebar };
    default:
      throw new Error(`Unhandled sidebar action type: ${action.type}`)
  }
}
