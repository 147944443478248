import React, { useState, useEffect, useCallback } from "react";
import { Box, Divider, Grid } from "@mui/material";
import KrgSnackbar, { TYPE_ENUM } from "@kargo/shared-components.krg-snackbar";
import { useQueryClient } from "react-query";
import { debounce } from "lodash";
import useVideos from "../../hooks/useVideos";
import SearchInput from "../../components/SearchInput";
import CategoriesSelect from "../../components/CategoriesSelect/CategoriesSelect";
import { DatePicker, DateRange } from "../../components/DatePicker/DatePicker";
import LoadingCircle from "../../components/LoadingCircle";
import tracker from "../../helpers/tracker";
import Snackbar from "../../components/Snackbar";
import { VideoCard } from "../../components/VideoCard";
import VideosTable from "../../components/VideosTable";
import useSnackbar from "../../hooks/useSnackbar";
import TagSelect from "../../components/TagSelect/TagSelect";
import { CategoryProps, SnackbarState } from "../../@types/media.types";
import { Video } from "../../api/types";
import { isEnableEditDelete } from "../../helpers/videos";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import VideoSnackbar from "../../components/VideosTable/VideoSnackbar";
import useAPI from "../../api/useAPI";

type Props = {
  asAdmin?: boolean;
  publisherSlug?: string;
  createOptions?: {
    isVisible: boolean;
    onClose: () => void;
    onSubmit: () => void;
  };
  selectedVideos: string[];
  onChangeSelected: (selectedVideos: string[]) => void;
  uploadPreviewVideoToken?: { token: string };
  setIsBulkDeleteOpen: (isOpen: boolean) => void;
  isBulkDeleteOpen: boolean;
};

export default function VideoLibrary({
  asAdmin,
  publisherSlug,
  selectedVideos,
  onChangeSelected,
  uploadPreviewVideoToken,
  isBulkDeleteOpen,
  setIsBulkDeleteOpen,
}: Props) {
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<CategoryProps[]>(
    [],
  );
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const { snackbarWarning } = useSnackbar();
  const { api } = useAPI();
  const queryClient = useQueryClient();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    type: TYPE_ENUM.success,
    isOpen: false,
    message: "",
  });
  // Query configuration based on filters
  const query = {
    ...(search.length >= 3 && { text: search }),
    ...(selectedCategories.length > 0 && {
      category: selectedCategories.map((category) =>
        category.name.toLowerCase(),
      ),
    }),
    ...(dateRange &&
      dateRange.to &&
      dateRange.from && { date_range: dateRange }),
    ...(publisherSlug && { slug: publisherSlug }),
  };

  const { videos, query: videosQuery } = useVideos(query);

  const debouncedTrack = React.useMemo(
    () =>
      debounce((event, data) => {
        tracker.track(event, data);
      }, 3000),
    [],
  );

  const handleCloseSnackbar = () => {
    setSnackbarState({ ...snackbarState, isOpen: false });
  };

  const handleConfirmBulkDelete = useCallback(async () => {
    try {
      await api.Media.bulkRemove({ tokens: selectedVideos });
      queryClient.invalidateQueries(["videos", query]);
      setSnackbarState({
        type: TYPE_ENUM.success,
        isOpen: true,
        message: `Successfully deleted video(s)`,
      });
      onChangeSelected([]);
    } catch (error) {
      setSnackbarState({
        type: TYPE_ENUM.error,
        isOpen: true,
        message: `Failed to deleted videos`,
      });
    } finally {
      setIsBulkDeleteOpen(false);
    }
  }, [isBulkDeleteOpen, api.Media, queryClient, query]);

  // Handle toggling selection for all videos
  const handleToggleAll = (checked: boolean) => {
    if (videos.length === 0 && checked) {
      snackbarWarning("Video Library selected is Empty");
      return;
    }
    if (checked) {
      const allVideoTokens = videos
        .filter((video: Video) => isEnableEditDelete(video.state))
        .map((video: Video) => video.token);
      onChangeSelected(allVideoTokens);
    } else {
      onChangeSelected([]);
    }
  };

  // Handle toggling selection for a single video
  const handleVideoToggle = (videoToken: string, isSelected: boolean) => {
    if (isSelected) {
      onChangeSelected([...selectedVideos, videoToken]);
    } else {
      onChangeSelected(selectedVideos.filter((token) => token !== videoToken));
    }
  };

  // Update showPlaylist based on admin status and publisher slug
  useEffect(() => {
    setShowPlaylist((asAdmin && Boolean(publisherSlug)) || !asAdmin);
  }, [asAdmin, publisherSlug]);

  // Track search and filter usage
  useEffect(() => {
    if (search || selectedCategories?.length > 0 || dateRange) {
      debouncedTrack("Search", {
        resource: "video",
        text: search,
        categories: selectedCategories,
        date: dateRange,
      });
    }
  }, [search, selectedCategories, dateRange]);
  return (
    <Box>
      <Grid
        item
        container
        xs={12}
        md={12}
        spacing={2}
        justifyContent="flex-start"
      >
        <Grid item xs={12} md="auto">
          <SearchInput placeholder="Search" onDebounceEnd={setSearch} />
        </Grid>
        <Grid item xs={12} md="auto">
          <CategoriesSelect
            asAdmin={asAdmin}
            value={selectedCategories}
            onChange={setSelectedCategories}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <TagSelect
            asAdmin={asAdmin}
            value={selectedTags}
            onChange={setSelectedTags}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <DatePicker
            onDateRangeChange={(selectedDate) => setDateRange(selectedDate)}
            value={dateRange}
          />
        </Grid>
      </Grid>
      <Divider
        variant="fullWidth"
        sx={{
          mt: "21px",
          borderColor: "transparent",
        }}
      />
      {videosQuery.isFetched ? (
        <>
          {uploadPreviewVideoToken?.token && (
            <Box style={{ padding: "10px 20px 20px 20px" }}>
              <Box display="flex">
                <VideoCard video={uploadPreviewVideoToken} />
              </Box>
            </Box>
          )}
          {showPlaylist && (
            <VideosTable
              videos={videos}
              selectedVideos={selectedVideos}
              onToggleAll={handleToggleAll}
              onVideoToggle={handleVideoToggle}
              query={query}
            />
          )}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 259px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingCircle />
        </div>
      )}
      {asAdmin && !showPlaylist && (
        <Snackbar
          autoHideDuration={6000}
          type={KrgSnackbar.TYPE_ENUM.warning}
          isOpen={isOpen}
          theme={KrgSnackbar.THEME_ENUM.v2}
          button={{
            text: "continue",
            onClick: () => {
              setIsOpen(false);
              console.warn(
                "Please switch to a publisher account at the top right-hand corner to view uploaded videos, MRSS feeds and playlists",
              );
            },
          }}
          text={
            <div style={{ fontSize: "13px" }}>
              <p>Please switch to a publisher account at the top right-hand</p>
              <p>corner to view uploaded videos, MRSS feeds and playlists</p>
            </div>
          }
        />
      )}
      {/* Bulk Delete Confirm Modal */}
      <ConfirmModal
        isOpen={isBulkDeleteOpen}
        onClose={() => setIsBulkDeleteOpen(false)}
        onSubmitClick={handleConfirmBulkDelete}
        header="Delete Video(s)"
        body="Deleting these video(s) will permanently remove them from your library and any existing playlists. Are you sure you want to proceed?"
        submitButtonText="Delete"
        cancelButtonText="Cancel"
      />
      <VideoSnackbar
        type={snackbarState.type}
        isOpen={snackbarState.isOpen}
        onClose={handleCloseSnackbar}
        message={snackbarState.message}
      />
    </Box>
  );
}
