import { TYPE_ENUM } from "@kargo/shared-components.krg-snackbar";
import { Video } from "../api/types";

export enum MediaProcessingStatusEnum {
  PROCESSING = "PROCESSING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export type SingleMediaEditForm = {
  token: string;
  url: string;
  title: string;
  tags: string[];
  categories: string[];
  description?: string;
};

export type CategoryProps = {
  id: number;
  name: string;
};

export type ActionType = "delete" | "restore";

export type ActionState = {
  isOpen: boolean;
  type: ActionType;
  video: Video | null;
};

export type SnackbarState = {
  type: TYPE_ENUM;
  isOpen: boolean;
  message: string;
};

export enum MediaState {
  EMPTY = "empty",
  PROCESSING = "processing",
  READY = "ready",
  UPLOADED = "uploaded",
  FAILED = "failed",
  SOFT_DELETED = "soft_deleted",
  HARD_DELETED = "hard_deleted",
  DRAFT = "draft",
}
