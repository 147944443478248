import { useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../api/useAPI";
import useSnackbar from "./useSnackbar";
import { Video } from "../api/types";

export default function useVideo(video: string, initialData?: any) {
  const { snackbarError, snackbarSuccess } = useSnackbar();
  const queryClient = useQueryClient();
  const { api, didLoad } = useAPI();
  const query = useQuery({
    queryKey: ["video", video],
    queryFn: () => video && api.Media.get(video).then((data) => data.media),
    initialData,
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: !!video && didLoad,
  });

  const updateMutation = useMutation({
    mutationFn: (data: Partial<Video>) => api.Media.update(video, data),
    onSuccess: ({ media }: { media: Video }) => {
      snackbarSuccess(`Video related information was updated`);
      queryClient.setQueryData(["video", video], media);
      queryClient.invalidateQueries({ queryKey: ["videos"] });
    },
    onError: (e) => {
      console.error(e);
      snackbarError(
        "Error while updating the video. Check the console for more information.",
      );
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (token: string) => api.Media.remove(token),
    onSuccess: () => {
      snackbarSuccess(`Video was successfully deleted`);
    },
    onError: (e) => {
      console.error(e);
      snackbarError(
        "Error while deleting the video. Check the console for more information.",
      );
    },
  });

  const uploadVTT = useMutation({
    mutationFn: (
      data: File,
      handleProgress?: (
        event: ProgressEvent<FileReader>,
        video?: string,
      ) => void,
    ) => api.Media.uploadSubtitle(video, data, handleProgress),
    onSuccess: () => {
      snackbarSuccess("VTT file was successfully uploaded");
    },
    onError: (e) => {
      console.error(e);
      snackbarError(
        "Error while uploading the VTT file. Check the console for more information.",
      );
    },
  });

  return {
    ...query,
    video: query.data,
    api: {
      update: updateMutation.mutate,
      delete: deleteMutation.mutate,
    },
    mutations: {
      updateMutation,
      deleteMutation,
      uploadVTT,
    },
  };
}
