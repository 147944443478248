import React from "react";
import AutoComplete from "../AutoComplete/AutoComplete";
import { CategoryProps } from "../../@types/media.types";
import { tier1Categories } from "../../constants/iab-t1-categories-v3";

type Props = {
  asAdmin?: boolean;
  isEnabled?: boolean;
  value?: CategoryProps[];
  onChange?: (cat: CategoryProps[]) => void;
};

export default function CategoriesSelect({
  isEnabled = true,
  value = [],
  onChange,
}: Props) {
  return (
    <AutoComplete
      id="categories-select"
      isEnabled={isEnabled}
      label="Category"
      placeholder="Add"
      value={value}
      onChange={onChange}
      options={tier1Categories}
    />
  );
}
