import { makeStyles } from '@mui/styles';
import { FONT, COLORS } from '@kargo/shared-components.krg-shared';
import { RESIZE_ENUM } from '../shared/enums';

export type Props = {
  resize:  RESIZE_ENUM,
  textColor?: string,
  labelColor?: string,
  placeholderColor?: string,
  borderColor?: string,
  isAutoHeight?: boolean,
};

const useBaseStyles = ({
    resize,
    textColor,
    labelColor,
    placeholderColor,
    borderColor,
    isAutoHeight,
}: Props) => makeStyles({
  /*
    Container class
  */
  container: {
    borderRadius: 0,
    color: textColor || COLORS.BLACK_HIGH_EMPHASIS,
    fontSize: FONT.SIZE.EXTRA_LARGE,
    lineHeight: 1.2,
  },

  textArea: {
    fontSize: FONT.SIZE.BASE,
    '& textarea': {
      resize,
      fontSize: FONT.SIZE.LARGE,
    },
    '& .MuiInput-formControl': {
      marginTop: '0',
    },
    // Textfield label
    '& .MuiInputLabel-root': {
      fontSize: '0.9em',
    },
    '& ::-webkit-input-placeholder': {
      color: placeholderColor || COLORS.BLACK_HIGH_EMPHASIS,
    },
    '& .MuiFormHelperText-root': {
      margin: '0.25em 0.0625em',
    },
  },
  textAreaStandard: {
    // Textarea label
    '& .MuiInputLabel-root': {
      fontSize: '0.9em',
    },
  },
  textAreaDisabled: {
    '& .MuiInput-underline:before': {
      borderBottomColor: COLORS.GRAY_DIVIDER, // Semi-transparent underline
      borderBottomStyle: 'solid',
    },
  },
  errorText: {
    width: '100%',
    color: COLORS.ERROR_DARK,
    fontSize: FONT.SIZE.MEDIUM,
    lineHeight: 1.4,
    overflow: isAutoHeight ? 'initial' : 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: isAutoHeight ? 'normal' : 'nowrap',
  },
});

export default useBaseStyles;
