import React, { useRef } from "react";
import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
} from "@mui/material";

import KrgTextArea from "@kargo/shared-components.krg-text-area";
import KrgChip, {
  THEME_ENUM,
  TYPE_ENUM,
} from "@kargo/shared-components.krg-chip";
import { ChipData } from "@kargo/shared-components.krg-chip/dist/shared/interfaces";
import KrgTextInput, {
  VARIANT_ENUM,
} from "@kargo/shared-components.krg-text-input";
import KrgSelect, {
  ItemI,
  MULTIPLE_TYPE_ENUM,
} from "@kargo/shared-components.krg-select";

import { Video } from "../../../api/types";
import InfoPopover from "../../../components/InfoPopover";
import {
  handleAddTag,
  handleDeleteTag,
  handleOnChange,
} from "../../../context/form/form.actions";
import { useFormContext } from "../../../context/form/form.provider";
import UploadVTTInput from "../../../components/UploadVTTInput";
import CopyToClipboard from "../../../components/CopyToClipboard";

const HinterText = ({ text, errors }: { text: string; errors: string[] }) => (
  <>
    {text && typeof errors === "undefined" && (
      <FormHelperText>{text}</FormHelperText>
    )}
    {errors?.length > 0 &&
      errors.map((err) => (
        <FormHelperText key={err} error color="error">
          {err}
        </FormHelperText>
      ))}
  </>
);

/**
 * SingleVideoEditor
 * @param useFormSate
 * @constructor
 */
const SingleMediaEditor = ({ video }: { video: Video }) => {
  const [{ formInputFields, errors }, dispatch] = useFormContext();
  const tagInputRef = useRef<HTMLInputElement>(null);

  const { title, description, tags, category } = formInputFields;

  return (
    <Box id="media-editor-form-container">
      {/* title */}
      {title && (
        <FormGroup
          className={`form-group-container ${errors?.title && "error-field-group"}`}
        >
          <InputLabel>{title.label}</InputLabel>
          <FormControl error={!!errors?.title}>
            <KrgTextInput
              id="media-title"
              name={title.name || "title"}
              variant={VARIANT_ENUM.outlined}
              text={title.value?.toString() || ""}
              style={{ height: "auto" }}
              onTextChange={(text) => handleOnChange(dispatch, title, text)}
            />
            <HinterText text={title.hinterText} errors={errors?.title} />
          </FormControl>
        </FormGroup>
      )}

      {/* description */}
      {description && (
        <FormGroup
          className={`form-group-container ${errors?.description && "error-field-group"}`}
        >
          <InputLabel>
            {description.label}
            {description.popoverText && (
              <InfoPopover text={description.popoverText} />
            )}
          </InputLabel>
          <FormControl error={!!errors?.description}>
            <KrgTextArea
              name={description.name || "description"}
              rows={6}
              placeholder="Add a description"
              text={description.value?.toString() || ""}
              hasError={!!errors.description}
              onTextChange={(value) =>
                handleOnChange(dispatch, description, value)
              }
            />
            <HinterText
              text={description.hinterText}
              errors={errors?.description}
            />
          </FormControl>
        </FormGroup>
      )}

      {/* category */}
      {category && category.options && (
        <FormGroup
          className={`form-group-container ${errors?.category && "error-field-group"}`}
        >
          <InputLabel>
            {category.label}
            {category.popoverText && (
              <InfoPopover text={category.popoverText} />
            )}
          </InputLabel>
          <FormControl error={!!errors?.category}>
            <KrgSelect
              value={category.value as string[]}
              items={category.options as ItemI[]}
              multipleType={MULTIPLE_TYPE_ENUM.default}
              onChange={(value: string) =>
                handleOnChange(dispatch, category, value)
              }
              isMultiple
              renderValue
              isFullWidth
            />
            <HinterText text={category.hinterText} errors={errors?.category} />
          </FormControl>
        </FormGroup>
      )}

      {/* tags */}
      {tags && (
        <FormGroup
          className={`form-group-container ${errors?.tags && "error-field-group"}`}
        >
          <InputLabel>
            {tags.label}
            {tags.popoverText && <InfoPopover text={tags.popoverText} />}
          </InputLabel>
          <FormControl error={!!errors?.tags}>
            <Box
              className="chip-container"
              onClick={() => {
                tagInputRef.current?.focus();
              }}
            >
              {tags.value &&
                (tags.value as ChipData[]).map((t: ChipData) => (
                  <KrgChip
                    key={`chip-${t.id}`}
                    className="tags-chip"
                    theme={THEME_ENUM.v2}
                    type={TYPE_ENUM.gray}
                    data={{ text: t.text, id: t.id }}
                    onDelete={() => handleDeleteTag(dispatch, t, tags)}
                  />
                ))}
              <input
                type="text"
                onKeyDown={(ev) =>
                  handleAddTag(dispatch, ev, tags, tagInputRef)
                }
                placeholder="Add a tag"
                ref={tagInputRef}
              />
            </Box>
          </FormControl>
        </FormGroup>
      )}

      {/* media URL */}
      {video.default_stream?.url && (
        <CopyToClipboard data={video.default_stream?.url} title="Media Link" />
      )}

      {/* VTT File Attachment */}
      {video.token && <UploadVTTInput token={video.token} />}
    </Box>
  );
};

export default SingleMediaEditor;
