export const tier1Categories = [
  { id: "1", name: "Attractions" },
  { id: "2", name: "Automotive" },
  { id: "3", name: "Books and Literature" },
  { id: "4", name: "Business and Finance" },
  { id: "5", name: "Careers" },
  { id: "6", name: "Communication" },
  { id: "7", name: "Crime" },
  { id: "8", name: "Disasters" },
  { id: "9", name: "Education" },
  { id: "10", name: "Entertainment" },
  { id: "11", name: "Events" },
  { id: "12", name: "Family and Relationships" },
  { id: "13", name: "Fine Art" },
  { id: "14", name: "Food & Drink" },
  { id: "15", name: "Genres" },
  { id: "16", name: "Healthy Living" },
  { id: "17", name: "Hobbies & Interests" },
  { id: "18", name: "Holidays" },
  { id: "19", name: "Home & Garden" },
  { id: "20", name: "Law" },
  { id: "21", name: "Maps & Navigation" },
  { id: "22", name: "Medical Health" },
  { id: "23", name: "Personal Celebrations & Life Events" },
  { id: "24", name: "Personal Finance" },
  { id: "25", name: "Pets" },
  { id: "26", name: "Politics" },
  { id: "27", name: "Pop Culture" },
  { id: "28", name: "Productivity" },
  { id: "29", name: "Real Estate" },
  { id: "30", name: "Religion & Spirituality" },
  { id: "31", name: "Science" },
  { id: "32", name: "Sensitive Topics" },
  { id: "33", name: "Shopping" },
  { id: "34", name: "Sports" },
  { id: "35", name: "Style & Fashion" },
  { id: "36", name: "Technology & Computing" },
  { id: "37", name: "Travel" },
  { id: "38", name: "Video Gaming" },
  { id: "39", name: "War and Conflicts" },
];
