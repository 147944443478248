import React from "react";
import KrgTabs from "@kargo/shared-components.krg-tabs";
import { Box } from "@mui/material";
import Pagination from "../Pagination";

type Props = { tabsConfig: React.ComponentProps<typeof KrgTabs> };
export default function PaginatedTabs({ tabsConfig }: Props) {
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 4;
  const totalPages = Math.ceil(tabsConfig.tabs.length / itemsPerPage);
  const tabs = tabsConfig.tabs.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );
  const handlePageChange = (e, value) => {
    setPage(value);
    const newSelTab =
      (value - 1) * itemsPerPage + tabsConfig.selectedTab > tabs.length
        ? 0
        : tabsConfig.selectedTab;
    tabsConfig.onChange(null, (value - 1) * itemsPerPage + newSelTab);
  };
  const handleTabSelection = (e: any, value?: any) => {
    tabsConfig.onChange(e, (page - 1) * itemsPerPage + value);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      sx={{
        "& .MuiTabs-root .MuiTab-root": {
          borderBottom: 0, // Override the borderBottom for each Tab as the style is applied within the KrgTabs component
        },
      }}
    >
      <KrgTabs
        variant={KrgTabs.VARIANT_ENUM.fullWidth}
        theme={KrgTabs.THEME_ENUM.v2}
        {...tabsConfig}
        tabs={tabs}
        onChange={handleTabSelection}
      />
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          boundaryCount={1}
          siblingCount={0}
          size="small"
        />
      )}
    </Box>
  );
}
