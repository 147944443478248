import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Circle } from "@mui/icons-material";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { sidebarNavItems } from "../../constants/constants";

import { ArrowDown, ArrowUp } from "../Icons";
import useSession from "../../hooks/useSession";
import { PATHS } from "../../constants/paths";
import { useSidebarContext } from "../../context/sidebar/sidebar.provider";
import { SideBarProps, SidebarListItemButtonProps } from "../../@types/sidebar.types";

import "./styles.scss";

function LeftListItemIcon({
  color,
}: {
  color?: "primary" | "secondary" | undefined;
}) {
  return (
    <ListItemIcon sx={{ minWidth: "30px" }}>
      <Circle sx={{ fontSize: "15px" }} color={color} />
    </ListItemIcon>
  );
}

function RightListItemIcon({ children }: React.PropsWithChildren) {
  return <ListItemIcon sx={{ minWidth: 0 }}>{children}</ListItemIcon>;
}

const baseListItemButtonSx = {
  px: "24px",
  height: "46px",
  borderBottom: "solid 1px #c8d0d6",
};

function SidebarListItemButton({
  onClick,
  selected,
  text,
  iconElement
}: SidebarListItemButtonProps) {
  return (
    <ListItemButton
      sx={{ ...baseListItemButtonSx }}
      onClick={onClick}
      selected={selected}
    >
      {iconElement || <LeftListItemIcon color={selected ? "primary" : undefined} />}
      <ListItemText
        primary={text}
        primaryTypographyProps={
          selected ? { sx: { fontWeight: 600 } } : undefined
        }
      />
    </ListItemButton>
  );
}

export function Sidebar({ isPublisherAdmin }: SideBarProps) {
  const location = useLocation();
  const session = useSession();
  const history = useHistory();

  const isPathActive = (path: string): boolean =>
    !!matchPath(location.pathname, {
      path,
      exact: false,
      strict: false,
    });

  const [open, setOpen] = useState(isPathActive(PATHS.ADMIN));
  const [bulkEdit, setBulkEditOpen] = useState(
    location.pathname.includes(PATHS.BULK_EDIT),
  );

  const userGroups =
    session && session.getAccessToken().payload["cognito:groups"];
  const isSuperAdmin = userGroups && userGroups.includes("admin");
  const userIsAdmin = isSuperAdmin || isPublisherAdmin;
  const [{ singlePageSidebar }] = useSidebarContext();

  if (singlePageSidebar && singlePageSidebar.length > 0) {
    return (<List className="sidebar-container" disablePadding>{
      singlePageSidebar.map((sb: SidebarListItemButtonProps) => {
        const { path, text, onClick, iconElement } = sb;
        return(<SidebarListItemButton
          text={text}
          key={`custom-sidebar-item-${text}`}
          iconElement={iconElement}
          selected={isPathActive(path)}
          onClick={onClick || (() => history.push(path))}
        />)
      })
    }</List>);
  }

  return (<List sx={{ boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)" }}>
      {sidebarNavItems.map((item) => (
        <SidebarListItemButton
          key={item.path}
          onClick={() => history.push(item.path)}
          selected={isPathActive(item.path)}
          text={item.text}
        />
      ))}
      {userIsAdmin && (
        <>
          <ListItemButton
            sx={baseListItemButtonSx}
            onClick={() => setOpen(!open)}
          >
            <LeftListItemIcon
              color={isPathActive(PATHS.ADMIN) ? "primary" : undefined}
            />
            <ListItemText
              primary="Admin"
              primaryTypographyProps={
                isPathActive(PATHS.ADMIN)
                  ? { sx: { fontWeight: 600 } }
                  : undefined
              }
            />
            <RightListItemIcon>
              {open ? (
                <ArrowUp alt="expand less" style={{ width: "9px" }} />
              ) : (
                <ArrowDown alt="expand more" style={{ width: "9px" }} />
              )}
            </RightListItemIcon>
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <SidebarListItemButton
                onClick={() => history.push(PATHS.ADMIN_USERS)}
                selected={isPathActive(PATHS.ADMIN_USERS)}
                text="User management"
              />
              {isSuperAdmin && (
                <>
                  <SidebarListItemButton
                    onClick={() => history.push(PATHS.ADMIN_DOMAIN)}
                    selected={location.pathname.includes(PATHS.ADMIN_DOMAIN)}
                    text="Domain management"
                  />
                  <SidebarListItemButton
                    onClick={() => history.push(PATHS.ADMIN_PUBLISHER_ONBOARD)}
                    selected={location.pathname.includes(
                      PATHS.ADMIN_PUBLISHER_ONBOARD,
                    )}
                    text="Onboard Publishers"
                  />
                </>
              )}
              <ListItemButton
                sx={{ ...baseListItemButtonSx }}
                onClick={() => setBulkEditOpen(!bulkEdit)}
              >
                <LeftListItemIcon
                  color={
                    location.pathname.includes(PATHS.BULK_EDIT)
                      ? "primary"
                      : undefined
                  }
                />
                <ListItemText
                  primary="Bulk Edit"
                  primaryTypographyProps={
                    location.pathname.includes(PATHS.BULK_EDIT)
                      ? { sx: { fontWeight: 600 } }
                      : undefined
                  }
                />
                <RightListItemIcon>
                  {bulkEdit ? (
                    <ArrowUp alt="expand less" style={{ width: "9px" }} />
                  ) : (
                    <ArrowDown alt="expand more" style={{ width: "9px" }} />
                  )}
                </RightListItemIcon>
              </ListItemButton>
              <Collapse in={bulkEdit} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <SidebarListItemButton
                    onClick={() => history.push(PATHS.ADMIN_BULK_UPDATE)}
                    selected={location.pathname.includes(
                      PATHS.ADMIN_BULK_UPDATE,
                    )}
                    text="Update"
                  />
                  <SidebarListItemButton
                    onClick={() => history.push(PATHS.ADMIN_BULK_DELETE)}
                    selected={location.pathname.includes(
                      PATHS.ADMIN_BULK_DELETE,
                    )}
                    text="Delete"
                  />
                </List>
              </Collapse>
            </List>
          </Collapse>
        </>
      )}
    </List>
  );
}
