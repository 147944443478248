import React, { FormEvent, MouseEvent, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { VARIANT_ENUM } from "@kargo/shared-components.krg-text-input";
import Delete from "@mui/icons-material/Delete";
import KrgButton from "@kargo/shared-components.krg-button";

import { Video } from "../../../api/types";
import { FormActionsEnum, FormInitInputs } from "../../../@types/form.types";
import useVideo from "../../../hooks/useVideo";
import LoadingCircle from "../../../components/LoadingCircle";
import { VideoPlayer } from "../../../components/VideoPlayer";
import { useFormContext } from "../../../context/form/form.provider";
import SingleMediaActionButtons from "./SingleMediaActionButtons";
import SingleMediaEditor from "./SingleMediaEditor";
import { PATHS } from "../../../constants/paths";
import {
  submitForm,
  checkInitialFormValidation,
} from "../../../context/form/form.actions";
import ConfirmModal from "../../../components/Modals/ConfirmModal";

type SingleMediaPageProps = {
  video: Video;
  token: string;
  initialFormState: FormInitInputs;
};

const SingleMediaPageContainer = ({
  video,
  token,
  initialFormState,
}: SingleMediaPageProps) => {
  const { push } = useHistory();
  const [
    { formInputFields, errors, data, showAllErrors, submitting, touched },
    dispatch,
  ] = useFormContext();
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const {
    mutations: { updateMutation, deleteMutation },
  } = useVideo(token);
  const { mutate } = updateMutation;

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      queryClient
        .resetQueries(["videos"])
        .then(() => push(PATHS.MEDIA_GALLERY));
    }
  }, [deleteMutation.isSuccess]);

  useEffect(() => {
    if (!touched && formInputFields) {
      checkInitialFormValidation(dispatch, formInputFields);
    }
  }, []);

  const submitHandler = async (ev: FormEvent | MouseEvent) => {
    ev.preventDefault();
    submitForm(dispatch, errors, data, mutate);
  };

  if (!video)
    return (
      <Grid container justifyContent="center">
        <LoadingCircle />
      </Grid>
    );

  return (
    <Container className="page-container">
      {/* Confirm Modal */}
      <ConfirmModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmitClick={() => deleteMutation.mutate(token)}
        header="Deleting Video"
        body="Are you sure you want to delete this video?"
        submitButtonText="Delete"
        cancelButtonText="Cancel"
      />

      <Box className="page-title-container">
        <Typography variant="h1" className="page-title">
          {formInputFields.title?.value?.toString()}
        </Typography>
      </Box>
      <SingleMediaActionButtons
        initialFormState={initialFormState}
        submitHandler={submitHandler}
      />

      {Object.keys(errors).length > 0 && showAllErrors && (
        <Alert
          severity="error"
          sx={{ marginBottom: "20px" }}
          onClose={() => dispatch({ type: FormActionsEnum.CLOSE_FORM_ERROR })}
        >
          {Object.keys(errors).map((errKey) =>
            errors[errKey].map((err: string) => (
              <Typography key={`alert-${err}`}>{err}</Typography>
            )),
          )}
        </Alert>
      )}

      <Stack>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 2, md: 1 }}
            sx={{ paddingLeft: 0 }}
          >
            {submitting ? (
              <LoadingCircle />
            ) : (
              <form onSubmit={submitHandler}>
                <SingleMediaEditor video={video} />
              </form>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 1, md: 2 }}
            sx={{ marginBottom: "25px" }}
          >
            <Card>
              <CardMedia
                component="div"
                sx={{
                  padding: "0.5em",
                  backgroundColor: "#f1f1f1",
                }}
              >
                <VideoPlayer video={token} />
              </CardMedia>
            </Card>
          </Grid>
        </Grid>
      </Stack>
      <Box>
        <KrgButton
          id="delete-video"
          className="form-delete-button"
          text="Delete Video"
          onClick={() => setShowModal(true)}
          variant={VARIANT_ENUM.outlined}
          isSquaredBorder
          startIcon={<Delete />}
          style={{
            marginTop: "17px",
            border: "none",
            paddingLeft: 0,
          }}
        />
      </Box>
    </Container>
  );
};

export default SingleMediaPageContainer;
