import { useEffect } from 'react';
import { useSidebarContext  } from "../context/sidebar/sidebar.provider";
import { SidebarActionEnum, SidebarListItemButtonProps, } from "../@types/sidebar.types";

const useSinglePageSidebar = (navItems: SidebarListItemButtonProps[]) => {
  const [sidebarState, sidebarDispatch] = useSidebarContext();

  useEffect(() => {
    sidebarDispatch({ type: SidebarActionEnum.SET_SINGLE_PAGE_SIDEBAR, payload: {
        ...sidebarState,
        singlePageSidebar: navItems
      }});
    return () => {
      sidebarDispatch({ type: SidebarActionEnum.SET_MAIN_SIDEBAR });
    }
  }, []);
}

export default useSinglePageSidebar;
