import { makeStyles } from '@mui/styles';
import { FONT, COLORS_V2 } from '@kargo/shared-components.krg-shared';
import { Props } from './base-style';

const useStylesV2 = ({
  labelColor,
  borderColor,
  isAutoHeight,
}: Props) => makeStyles({
  textAreaEnabled: {
    // Border style for underline
    '& .MuiInput-underline:hover:not(.Mui-disabled):not(.Mui-focused):before': {
      borderBottomColor: borderColor || COLORS_V2.NEUTRAL_BLACK,
      borderWidth: '0.0625em',
    },
    '& .MuiInput-underline.Mui-focused:hover:before': {
      border: 'none',
    },
    '& .MuiInput-underline:after': {
      transform: 'scaleX(1)',
      border: 'none',
    },
    '& .MuiInput-underline.Mui-focused:after': {
      borderBottomWidth: '0.125em',
      borderBottomStyle: 'solid',
      borderBottomColor: borderColor || COLORS_V2.PRIMARY_700,
    },

    // Border style for outline
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: borderColor || COLORS_V2.NEUTRAL_DARK,
      },
      '&:hover fieldset': {
        borderColor: borderColor || COLORS_V2.NEUTRAL_BLACK,
        opacity: 1,
      },
      '&.Mui-focused fieldset': {
        borderColor: borderColor || COLORS_V2.PRIMARY_700,
        opacity: 1,
      },
    },

    // Input style
    '& .MuiInputBase-root': {
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: borderColor || COLORS_V2.PRIMARY_700,
        },
      },
    },
    // Label style
    '& .MuiInputLabel-root': {
      color: labelColor || COLORS_V2.NEUTRAL_DARK,

      '&.MuiFormLabel-filled': {
        color: labelColor || COLORS_V2.NEUTRAL_BLACK,
      },
      '&.Mui-focused': {
        color: labelColor || COLORS_V2.PRIMARY_700,
      },
    },
  },
  textAreaDisabled: {
    '& .MuiInput-underline.Mui-error:after': {
      borderWidth: '0.0625em',
      borderBottomColor: COLORS_V2.NEUTRAL_DARK, // Solid underline on focus
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS_V2.NEUTRAL_DARK,
      },
    },
    '& .MuiFormHelperText-root': {
      color: COLORS_V2.ERROR_DARK,
    },
  },
  textAreaError: {
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: COLORS_V2.ERROR_DARK, // Solid underline on focus
    },
    '& .MuiFormHelperText-root': {
      color: COLORS_V2.ERROR_DARK,
      fontSize: FONT.SIZE.MEDIUM,
      lineHeight: 1.4,
      overflow: isAutoHeight ? 'initial' : 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: isAutoHeight ? 'normal' : 'nowrap',
    },
    '& .MuiInputLabel-root': {
      color: COLORS_V2.ERROR_DARK,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS_V2.ERROR_DARK,
        borderWidth: '0.12em',
      },
      '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS_V2.ERROR_DARK,
      },
    },
  },
});

export default useStylesV2;
