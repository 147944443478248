import { makeStyles } from '@mui/styles';
import { FONT, COLORS } from '@kargo/shared-components.krg-shared';

const useStylesV1 = makeStyles({
  textFieldRoot: {
    '& > div:nth-of-type(1)': {
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.ERROR_DARK,
      },
      '& .MuiSvgIcon-root': {
        color: COLORS.ERROR_DARK,
      },
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: COLORS.ERROR_DARK,
    },
    // Helper text
    '& .MuiFormHelperText-root': {
      fontSize: FONT.SIZE.REGULAR,
      margin: '0.25em 0.0625em',
      '&.Mui-error': {
        color: COLORS.ERROR_DARK,
      },
    },
  },

  textFieldEnabled: {},
  textFieldError: {},
  textFieldDisabled: {},

  errorIcon: {
    color: COLORS.ERROR_DARK,
  },
  listItem: {},
});

export default useStylesV1;
