import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { Close, Delete, Edit, FilterNone, Sort } from "@mui/icons-material";
import KrgTextInput from "@kargo/shared-components.krg-text-input";
import {
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
  SIZE_ENUM as BUTTON_SIZE_ENUM,
} from "@kargo/shared-components.krg-button";
import { makeStyles } from "@mui/styles";
import KrgTooltip from "@kargo/shared-components.krg-tooltip";
import KrgChip from "@kargo/shared-components.krg-chip";
import KrgSnackbar from "@kargo/shared-components.krg-snackbar";
import { Playlist } from "../../api/types";
import usePlaylists from "../../hooks/usePlaylists";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import getDuplicateName from "../../helpers/getDuplicateName";
import useSnackbar from "../../hooks/useSnackbar";
import EditPlaylistModal from "./EditPlaylistModal";
import { VideosCollection } from "../../components/VideosCollection";
import TrackedKrgButton from "../../components/TrackedButton/TrackedKrgButton";
import MenuButton from "../../components/Menu/MenuButton";
import SortPlaylistsVideosModal from "../../components/Modals/SortPlaylistsVideosModal";
import Snackbar from "../../components/Snackbar";

function ArrowDown({ className }: { className?: string }) {
  const arrowDown = new URL(
    "../../assets/arrow_up.svg",
    import.meta.url,
  ).toString();
  return (
    <img
      className={className}
      src={arrowDown}
      style={{
        width: "12px",
        marginLeft: "0px",
        marginRight: "12px",
        filter: "brightness(0)",
      }}
      alt="expand less"
    />
  );
}

const useStyles = makeStyles({
  holder: {
    marginLeft: "0px !important",
  },
  summary: {
    padding: 0,
    borderBottom: "1px solid black",
    minHeight: "47px",
    "& .MuiAccordionSummary-content": {
      transform: "translate(10px)",
      justifyContent: "space-between",
      margin: ({ isCopy }: { isCopy: boolean }) =>
        isCopy ? "14px 0 10px 0" : 0,
    },
    "&.Mui-expanded": {
      minHeight: "47px",
      "& $arrow": {
        transform: "rotate(180deg)",
      },
    },
  },
  arrow: {
    transition: "transform .3s ease-in-out",
  },
  details: {
    maxHeight: "520px",
    overflowY: "scroll",
    padding: "24px 0",
  },
  input: {
    height: "37px",
  },
});

type Props = {
  playlist: Playlist;
  isCopy?: boolean;
  onCopy?: () => void;
};
export default function PlaylistAccordion({ playlist, isCopy, onCopy }: Props) {
  const { api } = usePlaylists();
  const [confirmModalIsOpen, setConfirmModalIsOpen] = React.useState(false);
  const [showSortingModal, setShowSortingModal] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const classes = useStyles({ isCopy });
  const { snackbarError, snackbarSuccess } = useSnackbar();
  const [isPlaylistSortShuffled, setIsPlaylistSortShuffled] =
    React.useState(false);
  const [title, setTitle] = React.useState(
    isCopy
      ? getDuplicateName(playlist.title || playlist.token)
      : playlist.title,
  );
  return (
    <>
      <SortPlaylistsVideosModal
        isOpen={showSortingModal}
        playlist={playlist}
        onClose={(shuffle) => {
          setShowSortingModal(false);
          if (shuffle === "shuffle") setIsPlaylistSortShuffled(true);
        }}
      />
      <EditPlaylistModal
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
        playlist={playlist}
      />
      <Accordion elevation={0} className={classes.holder}>
        <AccordionSummary
          aria-controls={playlist.token}
          className={classes.summary}
          id={playlist.token}
        >
          <Box display="flex" alignItems="center">
            {isCopy ? (
              <KrgTextInput
                className={classes.input}
                theme={KrgTextInput.THEME_ENUM.v2}
                label="Playlist Name"
                text={title}
                onClick={(e) => e.stopPropagation()}
                onDebounceEnd={setTitle}
                onTextChange={setTitle}
                debounceTime={0}
              />
            ) : (
              <>
                <ArrowDown className={classes.arrow} />
                <Box display="flex" flexDirection="column">
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {playlist.title || playlist.key || playlist.token}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            {playlist.shuffle && (
              <KrgChip
                style={{ margin: "2px" }}
                theme={KrgChip.THEME_ENUM.v2}
                type={KrgChip.TYPE_ENUM.gray}
                data={{ text: "Shuffle", id: "" }}
              />
            )}
            {isCopy ? (
              <>
                <TrackedKrgButton
                  id="update-playlist"
                  variant={BUTTON_VARIANT_ENUM.contained}
                  size={BUTTON_SIZE_ENUM.small}
                  text="Save Playlist"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isCopy) {
                      api.create({ title, medias: playlist.medias });
                      onCopy();
                      return;
                    }
                    api.update({
                      token: playlist.token,
                      data: { title },
                    });
                  }}
                />
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onCopy();
                  }}
                >
                  <Close sx={{ color: "#303237", fontSize: "20px" }} />
                </IconButton>
              </>
            ) : (
              <>
                <Typography
                  sx={{ fontSize: "10px", marginRight: "12px" }}
                >{`(${playlist.medias.length}) Video${playlist.medias.length === 1 ? "" : "s"}`}</Typography>
                <KrgTooltip
                  theme={KrgTooltip.THEME_ENUM.v2}
                  tooltip="Copy playlist"
                >
                  <IconButton
                    disableRipple
                    onClick={(e) => {
                      e.stopPropagation();
                      onCopy();
                    }}
                    sx={{ paddingBottom: 0, paddingTop: 0 }}
                  >
                    <FilterNone sx={{ color: "#303237", fontSize: "20px" }} />
                  </IconButton>
                </KrgTooltip>
                <KrgTooltip
                  theme={KrgTooltip.THEME_ENUM.v2}
                  tooltip="Edit Playlist"
                >
                  <IconButton
                    disableRipple
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditing(true);
                    }}
                    sx={{ padding: 0 }}
                  >
                    <Edit sx={{ color: "#303237", fontSize: "20px" }} />
                  </IconButton>
                </KrgTooltip>
                <ConfirmModal
                  isOpen={confirmModalIsOpen}
                  onClose={(e) => {
                    e.stopPropagation();
                    setConfirmModalIsOpen(false);
                  }}
                  header={`Delete playlist ${playlist.title || playlist.token}`}
                  submitButtonText="Delete"
                  body="Are you sure you want to continue? This action can't be undone."
                  /* eslint-disable @typescript-eslint/no-shadow */
                  onSubmitClick={(e) => {
                    e.stopPropagation();
                    api.delete(playlist.token, {
                      onSuccess: () => snackbarSuccess("Playlist Deleted"),
                      onError: (e) => {
                        console.error(e);
                        snackbarError(
                          "Error while deleting playlist. Check the console for more information",
                        );
                      },
                    });
                    setConfirmModalIsOpen(false);
                  }}
                  /* eslint-enable @typescript-eslint/no-shadow */
                />
                <MenuButton
                  items={[
                    {
                      key: "sorting",
                      inner: (
                        <>
                          <Sort />
                          Change Sort Videos
                        </>
                      ),
                      onClick: () => {
                        setShowSortingModal(true);
                      },
                    },
                    {
                      key: "delete",
                      inner: (
                        <>
                          <Delete />
                          Delete Playlist
                        </>
                      ),
                      onClick: () => {
                        setConfirmModalIsOpen(true);
                      },
                    },
                  ]}
                />
              </>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {playlist.medias.length > 0 && (
            <VideosCollection
              isNumbered
              videos={playlist.medias.map((token: string) => ({ token }))}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <Snackbar
        autoHideDuration={6000}
        type={KrgSnackbar.TYPE_ENUM.success}
        isOpen={isPlaylistSortShuffled}
        theme={KrgSnackbar.THEME_ENUM.v2}
        button={{
          text: "continue",
          onClick: () => {
            setIsPlaylistSortShuffled(false);
          },
        }}
        text={
          <div style={{ fontSize: "13px" }}>
            <p style={{ fontWeight: "bold" }}>
              {playlist.title || playlist.token} is on shuffle mode
            </p>
            <p>The playlist will reshuffle every hour</p>
          </div>
        }
      />
    </>
  );
}
