import React from "react";

export default function arrowDown({
  alt,
  className,
  style,
}: {
  alt: string;
  className?: string;
  style?: React.CSSProperties;
}) {
  const src = new URL(
    "../../assets/arrow_down.svg",
    import.meta.url,
  ).toString();
  return <img src={src} className={className} style={style} alt={alt} />;
}
