import React, { SyntheticEvent } from "react";
import KrgSnackbar, { TYPE_ENUM } from "@kargo/shared-components.krg-snackbar";
import Snackbar from "../Snackbar";

interface VideoSnackbarProps {
  isOpen: boolean;
  onClose: () => void;
  button?: {
    text?: string;
    onClick?: (e: SyntheticEvent) => void;
  };
  type?: TYPE_ENUM;
  autoHideDuration?: number;
  message: string;
}

export default function VideoSnackbar({
  isOpen,
  onClose,
  button = undefined,
  type = KrgSnackbar.TYPE_ENUM.success,
  autoHideDuration = 6000,
  message,
}: VideoSnackbarProps) {
  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      type={type}
      isOpen={isOpen}
      theme={KrgSnackbar.THEME_ENUM.v2}
      onClose={onClose}
      button={button}
      text={
        <div style={{ fontSize: "14px" }}>
          <p>{message}</p>
        </div>
      }
    />
  );
}
