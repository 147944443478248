import React, { SyntheticEvent, useEffect } from "react";
import { Button, FormControl, FormGroup, Input } from "@mui/material";
import { AttachFile, Timer } from "@mui/icons-material";

import useVideo from "../../hooks/useVideo";
import useSnackbar from "../../hooks/useSnackbar";
import { ACCEPTED_VTT_FILE_MIME_TYPES } from "../../constants/constants";
import CopyToClipboard from "../CopyToClipboard";

const UploadVTTInput = ({ token }: { token: string }) => {
  const { snackbarWarning } = useSnackbar();
  const [fileURL, setFileURL] = React.useState<string | null>(null);
  const {
    mutations: {
      uploadVTT: { mutate, isLoading, data },
    },
  } = useVideo(token);

  useEffect(() => {
    if (typeof data === "string") {
      try {
        const resp = JSON.parse(data);
        if (resp?.data?.fileURL) setFileURL(resp?.data?.fileURL);
      } catch (e) {
        console.error(`Error parsing data`, e);
      }
    }
  }, [data]);

  const handleUploadVTT = (ev: SyntheticEvent) => {
    const file: File = (ev.target as HTMLInputElement)?.files[0];
    if (!file || !ACCEPTED_VTT_FILE_MIME_TYPES.includes(file?.type)) {
      snackbarWarning(
        `Invalid file type selected as VTT file only "${ACCEPTED_VTT_FILE_MIME_TYPES.map((f) => f.split("/")[1]).join(", ")}" types are allowed!`,
      );
      return;
    }
    mutate(file);
  };

  return (
    <>
      <FormGroup className="form-group-container">
        <FormControl className="file-input-controller">
          <Button
            component="label"
            disabled={isLoading}
            startIcon={
              isLoading ? (
                <Timer />
              ) : (
                <AttachFile
                  style={{
                    cursor: "pointer",
                    color: "#91A0AD",
                  }}
                />
              )
            }
            variant="outlined"
          >
            Click to attach or update VTT file
            <Input
              name="file"
              type="file"
              onChange={handleUploadVTT}
              inputProps={{
                accept: ".vtt",
                title: "Click to attach or update VTT file",
              }}
              hidden
            />
          </Button>
        </FormControl>
      </FormGroup>
      {fileURL && <CopyToClipboard data={fileURL} title="Copy VTT File URL" />}
    </>
  );
};

export default UploadVTTInput;
