import React, { useRef, ReactNode } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import { Events, THEME_ENUM, INPUT_VARIANT_ENUM as VARIANT_ENUM, DEPRECATED } from '@kargo/shared-components.krg-shared';
import KrgChip, { TYPE_ENUM as CHIP_TYPE_ENUM } from '@kargo/shared-components.krg-chip';
import KrgCheckbox from '@kargo/shared-components.krg-checkbox';
import useBaseStyles from './styles/base-style';
import useStylesV1 from './styles/style-v1';
import useStylesV2 from './styles/style-v2';

type Props = Events & {
  /**
   * @summary
   * Autocomplete id
   */
  id?: string,
  /**
   * @summary
   * Autocomplete class name
   */
  className?: string,
  /**
   * @summary
   * Limits the number of tags displayed in the autocomplete input.
   */
  limitTags?: number,
  /**
   * @summary
   * Autocomplete label
   */
  label?: string,
  /**
   * @summary
   * Autocomplete placeholder
   */
  placeholder?: string,
  /**
   * @summary
   * Selectable options array for autocomplete
   */
  options?: any[],
  /**
   * @summary
   * Value of Autocomplete.
   */
  value?: any,
  /**
   * @summary
   * Determines the Chip style
   * @default
   * CHIP_TYPE_ENUM.primary
   */
  chipType?: CHIP_TYPE_ENUM,
  /**
   * @summary
   * Textfield variant
   * @default
   * VARIANT_ENUM.outlined
   */
  variant?: VARIANT_ENUM,
  /**
   * @summary
   * Debounce time of the component in milliseconds
   * @default
   * 300
   */
  debounceTime?: number,
  /**
   * @summary
   * Shows helper message if presented
   */
  helperText?: string,
  /**
   * @summary
   * Shows error message if presented
   */
  errorMessage?: string,
  /**
   * @summary
   * Determines whether options have checkbox
   * @default
   * false
   */
  hasCheckbox?: boolean,
  /**
   * @summary
   * Shows error icon if presented and there is errorMessage
   */
  hasErrorIcon?: boolean,
  /**
   * @summary
   * Determines whether multi selection is possible
   * @default
   * false
   */
  isMultiple?: boolean,
  /**
   * @summary
   * Determines the loading state
   */
  isLoading?: boolean,
  /**
   * @summary
   * Is input enabled
   * @default
   * true
   */
  isEnabled?: boolean,
  /**
   * @summary
   * If true, the Autocomplete is free solo, meaning
   * that the user input is not bound to provided options.
   * @default
   * false
   */
  isFreeSolo?: boolean,
  /**
   * @summary
   * If true, the input's text is cleared on blur
   * if no value is selected.
   * @default
   * true
   */
  isClearedOnBlur?: boolean,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * On value change function.
   */
  onInputChange?: (event: any) => void,
  /**
   * @summary
   * This function is triggered when debounce is restarted
   */
  onDebounceRestart?: () => void,
  /**
   * @summary
   * This function is triggered when debounce ends
   */
  onDebounceEnd?: (text: string) => void,
  /**
   * @summary
   * Function that returns label from value objects
   */
  getOptionLabel?: (value: any) => string,
  /**
   * @summary
   * Function that returns Chip Data from option object
   */
  getChipData?: (data: any) => { text: string, id: string },
  /**
   * @summary
   * Function that returns label from value objects
   */
  filterOptions?: (options: any[], state: object) => any[],
  /**
   * @summary
   * Function that returns label from value objects
   */
  isOptionEqualToValue?: (options: any, value: any) => boolean,
  /**
   * @summary
   * render the option, use getOptionLabel by default.
   */
  renderOption?: (props: any, option: any, selection: any) => ReactNode,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.47 in favour of 'isMultiple'
   */
  multiple?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.47 in favour of 'isLoading'
   */
  loading?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.47 in favour of 'isEnabled'
   */
  disabled?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.47 in favour of 'hasCheckbox'
   */
  checkbox?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.47 in favour of 'isFreeSolo'
   */
  freeSolo?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.47 in favour of 'isClearedOnBlur'
   */
  clearOnBlur?: DEPRECATED,
};

const KrgAutocomplete = ({
  id,
  className = '',
  label,
  limitTags = -1,
  placeholder,
  options = [],
  value,
  chipType = CHIP_TYPE_ENUM.primary,
  variant = VARIANT_ENUM.outlined,
  debounceTime = 300,
  errorMessage,
  helperText,
  hasErrorIcon,
  hasCheckbox = false,
  isMultiple = false,
  isLoading = false,
  isEnabled = true,
  isFreeSolo = false,
  isClearedOnBlur = true,
  theme = THEME_ENUM.v1,
  getOptionLabel,
  getChipData,
  filterOptions,
  isOptionEqualToValue,
  renderOption,
  onDebounceRestart,
  onDebounceEnd,
  onInputChange,
  onChange,
  onFocus,
  onBlur,
  onClick,
}: Props) => {
  const isV2Theme = theme === THEME_ENUM.v2;
  const baseClasses = useBaseStyles();
  const classesV1 = useStylesV1();
  const classesV2 = useStylesV2();
  const classes = isV2Theme ? classesV2 : classesV1;

  const timer = useRef<any>(null);

  const onTextValueChange = (e: any) => {
    const textValue = e.target.value;
    onInputChange?.(textValue);
    if (onDebounceEnd) {
      handleDebounce(textValue);
    }
  };

  const handleDebounce = (textValue: string) => {
    if (timer && timer.current) {
      clearTimeout(timer.current);
    }
    onDebounceRestart?.();
    timer.current = setTimeout(
      () => {
        onDebounceEnd?.(textValue);
      },
      debounceTime,
    );
  };

  const textInput = (params: any) => {
    return (
      <TextField
        className={
          `${classes.textFieldRoot}` +
          ` ${errorMessage && isEnabled ? classes.textFieldError : ''}` +
          ` ${isEnabled ? classes.textFieldEnabled : ''}`
        }
        {...params}
        size="small"
        variant={variant}
        label={label}
        helperText={errorMessage || helperText}
        error={!!errorMessage}
        placeholder={placeholder}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            isLoading
              ? <CircularProgress
                className={baseClasses.circularProgress}
                size={20}
              />
              : hasErrorIcon && errorMessage
                ? <ErrorIcon className={classes.errorIcon} />
                : params.InputProps.endAdornment
          ),
        }}
        onChange={onTextValueChange}
      />
    );
  };

  const textFieldDisabledClass = isEnabled
    ? ''
    : `${baseClasses.textFieldDisabled} ${classes.textFieldDisabled}`;

  return (
    <Autocomplete
      id={id}
      className={`${textFieldDisabledClass} ${className}`}
      classes={{
        root: baseClasses.root,
        listbox: classes.listItem,
        loading: classes.listItem,
        noOptions: classes.listItem,
      }}
      limitTags={limitTags}
      disableCloseOnSelect={isMultiple}
      options={options}
      value={value}
      loading={isLoading}
      multiple={isMultiple}
      disabled={!isEnabled}
      freeSolo={isFreeSolo}
      clearOnBlur={isClearedOnBlur}
      renderTags={(tags, getTagProps) =>
        tags.map((option, index) => (
          <KrgChip
            data={getChipData ? getChipData(option) : option}
            type={chipType}
            theme={theme}
            isEnabled={!getTagProps({ index }).disabled}
            {...getTagProps({ index })}
            disabled={undefined} // Deprecated, override getTagProps
          />
        ))
      }
      renderInput={textInput}
      renderOption={
        hasCheckbox
          ? (props, option, { selected }) => (
            <li {...props}>
              <KrgCheckbox
                label={getOptionLabel ? getOptionLabel(option) : option}
                hasStopPropagation={false}
                isChecked={selected}
                theme={theme} />
            </li>)
          : renderOption
            ? (props, option, { selected }) => renderOption(props, option, { selected })
            : undefined
      }
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={onChange}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

KrgAutocomplete.CHIP_TYPE_ENUM = CHIP_TYPE_ENUM;
KrgAutocomplete.VARIANT_ENUM = VARIANT_ENUM;
KrgAutocomplete.THEME_ENUM = THEME_ENUM;

export default KrgAutocomplete;
