import React from "react";
import { Box } from "@mui/material";
import { LookerEmbeddedDashboard } from "../../components";

export default function Analytics() {
  const padding = "20px";
  return (
    <Box padding={padding}>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <LookerEmbeddedDashboard />
      </Box>
    </Box>
  );
}
