import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  Reducer,
  Dispatch,
  Context,
} from "react";

import {
  FormContextState,
  FormActions,
  FormContext,
  FormProvideProps,
  FormActionsEnum,
} from "../../@types/form.types";
import formReducer from "./form.reducer";

const initialFormState: FormContextState = {
  errors: {},
  touched: false,
  formInputFields: {},
  data: null,
  showAllErrors: false,
  submitting: false,
};

const CurrentFormContext: Context<FormContext> = createContext(null!);

export const FormProvider = ({
  children,
  formInputFields,
}: FormProvideProps) => {
  const [store, dispatch] = useReducer<Reducer<FormContextState, FormActions>>(
    formReducer,
    { ...initialFormState, formInputFields },
  );

  const contextMemorizedValue: [FormContextState, Dispatch<FormActions>] =
    useMemo(() => {
      // Set validated to true if any of the form fields are touched
      if (!store.touched && typeof store.formInputFields === "object") {
        if (
          Object.values(store.formInputFields).filter((f) => f.touched).length >
          0
        ) {
          dispatch({
            type: FormActionsEnum.SET_FORM_TOUCHED,
          });
        }
      }
      return [store, dispatch];
    }, [store]);

  return (
    <CurrentFormContext.Provider value={contextMemorizedValue}>
      {children}
    </CurrentFormContext.Provider>
  );
};

export const useFormContext = (): FormContext => {
  const context = useContext<FormContext>(CurrentFormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};
