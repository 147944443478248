import { makeStyles } from '@mui/styles';
import { FONT, COLORS_V2 } from '@kargo/shared-components.krg-shared';

const useStylesV2 = makeStyles({
  textFieldRoot: {
    // Root
    '& .MuiInputBase-root': {
      minHeight: '2.3125em',
    },

    // Root input
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      fontSize: FONT.SIZE.REGULAR,
      fontWeight: FONT.WEIGHT.MEDIUM,
      marginLeft: '0.25em',
      color: COLORS_V2.NEUTRAL_BLACK,
    },

    // Root input
    '& .MuiAutocomplete-inputRoot': {
      '& .MuiInputBase-input.Mui-disabled': {
        color: COLORS_V2.NEUTRAL_INACTIVE,
        WebkitTextFillColor: COLORS_V2.NEUTRAL_INACTIVE,
      },
    },

    '& > .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
      padding: '0.25em',
    },

    // Label style
    '& .MuiInputLabel-root': {
      color: COLORS_V2.NEUTRAL_DARK,

      // Label + Outlined
      '&.MuiInputLabel-outlined': {
        fontSize: FONT.SIZE.REGULAR,
      },

      // Label + standard
      '&.MuiInputLabel-standard': {
        fontSize: FONT.SIZE.SEMI_LARGE,
        marginTop: '0.5em',
      },

      // Label + Focused
      '&.Mui-focused': {
        fontSize: '0.8333333em',
        paddingLeft: '0.0625em',
      },

      // Label + Disabled
      '&.Mui-disabled': {
        color: COLORS_V2.NEUTRAL_INACTIVE,
      },
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink.MuiInputLabel-outlined': {
      fontSize: '0.8333333em',
      paddingLeft: '0.0625em',
    },

    // Borders standard
    '& .MuiInput-underline' : {
      '&:before': {
        borderBottomColor: COLORS_V2.NEUTRAL_DARK,
        borderWidth: '0.0625em',
      },

      '&:hover:not(.Mui-disabled):not(.Mui-focused):before': {
        borderBottomColor: COLORS_V2.NEUTRAL_BLACK,
        borderWidth: '0.0625em',
      },

      '&:not(.Mui-error):after': {
        transform: 'scaleX(1)',
        border: 'none',
      },

      '&.Mui-error.Mui-disabled:after': {
        border: 'none',
      },

      '&.Mui-focused:after': {
        borderBottomWidth: '0.125em',
        borderBottomStyle: 'solid',
        borderBottomColor: COLORS_V2.PRIMARY_700,
      },

      '&.Mui-error:after': {
        borderBottomColor: COLORS_V2.ERROR_DARK,
        borderWidth: '0.125em',
      },

      '&.Mui-disabled:before': {
        borderBottomColor: COLORS_V2.NEUTRAL_INACTIVE,
        borderWidth: '0.0625em',
      },
    },

    // Borders Outline
    '& .MuiOutlinedInput-root' : {
      '& .MuiOutlinedInput-notchedOutline' : {
        borderColor: COLORS_V2.NEUTRAL_DARK,
        borderRadius: 0,
        borderWidth: '0.0625em',
      },

      '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) > .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS_V2.NEUTRAL_BLACK,
        borderWidth: '0.0625em',
      },

      '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS_V2.PRIMARY_700,
        borderWidth: '0.125em',
      },

      '&.Mui-error > .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS_V2.ERROR_DARK,
        borderWidth: '0.125em',
      },

      '&.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS_V2.NEUTRAL_INACTIVE,
        borderWidth: '0.0625em',
      },
    },

    // Helper text
    '& .MuiFormHelperText-root': {
      margin: '0.545em 0 0.0625em 0',
      fontSize: FONT.SIZE.SMALL,
      color: COLORS_V2.NEUTRAL_DARK,

      // Helper + Error
      '&.Mui-error': {
        color: COLORS_V2.ERROR_DARK,
      },

      // Helper + Disabled
      '&.Mui-disabled': {
        color: COLORS_V2.NEUTRAL_INACTIVE,
      },
    },
  },

  textFieldEnabled: {
    // Label style
    '& .MuiInputLabel-root': {
      lineHeight: '1.6em',
      color: COLORS_V2.NEUTRAL_DARK,

      // Label + Focused
      '&.Mui-focused': {
        color: COLORS_V2.PRIMARY_700,
      },
    },
    '& .MuiInputLabel-root.MuiFormLabel-filled': {
      color: COLORS_V2.NEUTRAL_BLACK,
    },
  },

  textFieldDisabled: {},

  textFieldError: {
    // Error
    '& .MuiOutlinedInput-root.Mui-error:not(.Mui-disabled)': {
      // Error + Icon
      '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
        color: COLORS_V2.ERROR_DARK,
      },
    },

    // Label style
    '& .MuiInputLabel-root': {
      color: COLORS_V2.ERROR_DARK,

      // Label + Error
      '&.Mui-error': {
        color: COLORS_V2.ERROR_DARK,
      },
    },
  },

  listItem: {
    fontSize: FONT.SIZE.REGULAR,
    fontWeight: FONT.WEIGHT.MEDIUM,

    // Label + Focused
    '& > .MuiAutocomplete-option.Mui-focused': {
      color: COLORS_V2.PRIMARY_700,
    },
  },

  errorIcon: {
    color: COLORS_V2.ERROR_DARK,
    marginRight: '0.35em',
  },
});

export default useStylesV2;
