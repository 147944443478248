import React, { useRef } from "react";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { FormControl, FormGroup, InputLabel } from "@mui/material";
import KrgTextInput, {
  VARIANT_ENUM,
} from "@kargo/shared-components.krg-text-input";

import InfoPopover from "../InfoPopover";
import useSnackbar from "../../hooks/useSnackbar";

/**
 * @constructor
 */
const CopyToClipboard = ({
  data,
  title,
  infoPopover = true,
  customMessageOnCopy,
}: {
  data: string;
  title?: string;
  infoPopover?: string | boolean;
  customMessageOnCopy?: string;
}) => {
  const { snackbarSuccess } = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCopied = async (
    target: HTMLInputElement,
    postAction?: (args?: any) => void,
  ) => {
    const copiedValue = target.value;
    await navigator.clipboard.writeText(copiedValue);
    if (postAction && typeof postAction === "function") {
      postAction();
    }
  };

  return (
    <FormGroup className="form-group-container media-url-container">
      {title && (
        <InputLabel>
          {title}
          {infoPopover && (
            <InfoPopover
              text={
                typeof infoPopover === "string" ? infoPopover : "Click to copy"
              }
            />
          )}
        </InputLabel>
      )}
      <FormControl>
        <KrgTextInput
          name="media-url"
          variant={VARIANT_ENUM.outlined}
          text={data}
          endAdornment={
            <ContentCopy
              style={{
                cursor: "pointer",
                color: "#91A0AD",
              }}
              onClick={() =>
                inputRef.current &&
                handleCopied(inputRef.current, () =>
                  snackbarSuccess(
                    customMessageOnCopy || `URL ${data} was copied!`,
                  ),
                )
              }
            />
          }
          style={{
            height: "auto",
          }}
          inputRef={inputRef}
        />
      </FormControl>
    </FormGroup>
  );
};

export default CopyToClipboard;
