/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@mui/styles";
import KrgAutocomplete, {
  CHIP_TYPE_ENUM,
} from "@kargo/shared-components.krg-autocomplete";

const useStyles = makeStyles({
  autocomplete: {
    minHeight: "37px",
    "& .MuiTextField-root": {
      width: "260px",
      minHeight: "37px",
    },
    "& span.MuiAutocomplete-tag": {
      fontSize: "small",
    },
  },
});

export default function AutoComplete({
  isEnabled,
  className,
  options,
  onChange,
  isMultiple = true,
  value,
  theme,
  size = "small",
  chipType,
  ...props
}: React.ComponentProps<typeof KrgAutocomplete>) {
  const classes = useStyles();
  return (
    <KrgAutocomplete
      className={className || classes.autocomplete}
      theme={theme || KrgAutocomplete.THEME_ENUM.v2}
      isMultiple={isMultiple}
      isEnabled={isEnabled}
      disableCloseOnSelect
      limitTags={2}
      size={size}
      options={options}
      getOptionLabel={(option) => option?.name}
      value={value}
      onChange={(e, newValue) => onChange(newValue)}
      getChipData={(option) => ({ text: option.name, id: option.id })}
      chipType={chipType || CHIP_TYPE_ENUM.gray}
      hasCheckbox
      {...props}
    />
  );
}
