import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { Events, THEME_ENUM, INPUT_VARIANT_ENUM as VARIANT_ENUM, DEPRECATED } from '@kargo/shared-components.krg-shared';
import { ERRORS } from './shared/constants';
import { RESIZE_ENUM } from './shared/enums';
import useBaseStyles, { Props as StyleProps } from './styles/base-style';
import useStylesV1 from './styles/style-v1';
import useStylesV2 from './styles/style-v2';

type Props = Events & {
  /**
   * @summary
   * TextArea class name
   */
  className?: string,
  /**
   * @summary
   * TextArea style
   */
  style?: React.CSSProperties,
  /**
   * @summary
   * Border color.
   */
  borderColor?: string,
  /**
   * @summary
   * Value of TextArea.
   * @description
   * Set text for controlled input.
   */
  text?: any,
  /**
   * @summary
   * Debounce time of the component in milliseconds
   * @default
   * 300
   */
  debounceTime?: number,
  /**
   * @summary
   * Text color.
   */
  textColor?: string,
  /**
   * @summary
   * TextArea label
   */
  label?: string,
  /**
   * @summary
   * Label color.
   */
  labelColor?: string,
  /**
   * @summary
   * TextArea placeholder
   */
  placeholder?: string,
  /**
   * @summary
   * Placeholder color.
   */
  placeholderColor?: string,
  /**
   * @summary
   * Textfield variant
   * @default
   * VARIANT_ENUM.outlined
   */
  variant?: VARIANT_ENUM,
  /**
   * @summary
   * Shows error message if there is an error.
   */
  errorMessage?: string,
  /**
   * @summary
   * Maximum allowed text length
   * @description
   * Set this prop to show character counter helper
   * text at the bottom of the component.
   * If input length exceeds the max length,
   * helper text will be in the error state automatically.
   */
  maxCharacter?: number,
  /**
   * @summary
   * TextArea resize type
   * @default
   *  RESIZE_ENUM.vertical
   */
  resize?: RESIZE_ENUM,
  /**
   * @summary
   * TextArea initial row number
   * @default
   * 4
   */
  rows?: number,
  /**
   * @summary
   * TextArea max row number
   * @description
   * If rows exceed rowsMax value, text area will scroll
   */
  rowsMax?: number,
  /**
   * @summary
   * TextArea validations
   */
  validations?: {
    url: boolean,
  },
  /**
   * @summary
   * If `true`, the input will take up the full width of its container.
   * @default
   * true
   */
  isFullWidth?: boolean,
  /**
   * @summary
   * Determines whether height of the textarea auto.
   * @default false
   */
  isAutoHeight?: boolean,
  /**
   * @summary
   * Is input enabled / disabled
   * @default
   * true
   */
  isEnabled?: boolean,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * On text change function.
   * @description
   * Set this function for controlled input.
   */
  onTextChange?: (text: string) => void,
  /**
   * @summary
   * This function is triggered when debounce ends
   */
  onDebounceEnd?: (text: string) => void,
  /**
   * @summary
   * This function is triggered when debounce is restarted
   */
  onDebounceRestart?: () => void,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.35 in favour of 'isFullWidth'
   */
  fullWidth?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.35 in favour of 'isAutoHeight'
   */
  autoHeight?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.35 in favour of 'isEnabled'
   */
  disabled?: DEPRECATED,
};

const KrgTextArea = ({
  className,
  style,
  borderColor,
  text,
  debounceTime = 300,
  textColor,
  label,
  labelColor,
  placeholder,
  placeholderColor,
  maxCharacter,
  variant = VARIANT_ENUM.outlined,
  resize =  RESIZE_ENUM.vertical,
  rows = 4,
  rowsMax,
  errorMessage,
  validations,
  isFullWidth = true,
  isAutoHeight = false,
  isEnabled = true,
  theme = THEME_ENUM.v1,
  onTextChange,
  onDebounceEnd,
  onDebounceRestart,
  onClick,
  onFocus,
  onBlur,
}: Props) => {
  const [innerText, setInnerText] = useState<string>('');
  const [trackerValidation, setTrackerValidation] = useState({
    default: true,
    noscript: true,
    script: true,
    img: true,
    imgSrc: true,
    iframe: true,
  });
  const timer = useRef<any>(null);
  const isControlled = text !== undefined;
  const inputValue = isControlled ? text : innerText;

  const props: StyleProps = {
    resize,
    textColor,
    labelColor,
    placeholderColor,
    borderColor,
    isAutoHeight,
  };
  const baseClasses = useBaseStyles(props)();
  const classesV1 = useStylesV1(props)();
  const classesV2 = useStylesV2(props)();
  const classes = theme === THEME_ENUM.v2 ? classesV2 : classesV1;

  useEffect(
    () => {
      if (validations?.url) {
        handleUrlValidation();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue],
  );

  const handleUrlValidation = () => {
    const matchTags: any = {
      noscript: [],
      img: [],
      imgSrc: [],
      script: [],
      iframe: [],
      default: [],
    };
    const splitUrls = inputValue.split(/\r?\n/);
    const urls = splitUrls.filter((url: string) => {
      return url.trim().length > 0;
    });
    // validate url imp trackers
    urls.forEach((url: string) => {
      const trackerUrl = url.toLowerCase().trim();
      if (trackerUrl.startsWith('<script')) {
        matchTags.script.push(trackerUrl.endsWith('</script>'));
      } else if (trackerUrl.startsWith('<noscript')) {
        matchTags.noscript.push(trackerUrl.endsWith('</noscript>'));
      } else if (trackerUrl.startsWith('<img')) {
        const imgSrcMatch = trackerUrl.match(/<img[^>]+src=["']([^"'>]*)/gi) !== null;
        matchTags.img.push(trackerUrl.endsWith('/>'));
        matchTags.imgSrc.push(imgSrcMatch);
      } else if (trackerUrl.startsWith('<iframe')) {
        matchTags.iframe.push(trackerUrl.endsWith('</iframe>'));
      } else {
        // eslint-disable-next-line
        const defaultMatch = url.match(/^https:\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%\{\}]+$/) !== null;
        matchTags.default.push(defaultMatch);
      }
    });
    setTrackerValidation({
      noscript: matchTags.noscript.every((val: boolean) => val),
      img: matchTags.img.every((val: boolean) => val),
      imgSrc: matchTags.imgSrc.every((val: boolean) => val),
      script: matchTags.script.every((val: boolean) => val),
      iframe: matchTags.iframe.every((val: boolean) => val),
      default: matchTags.default.every((val: boolean) => val),
    });
  };

  const onTextValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isControlled) {
      onTextChange?.(value);

      if (onDebounceEnd) {
        handleDebounce(value);
      }
    } else {
      setInnerText(value);
      if (onDebounceEnd) {
        handleDebounce(value);
      }
    }
  };

  const handleDebounce = (value: string) => {
    if (timer && timer.current) {
      clearTimeout(timer.current);
    }
    onDebounceRestart?.();

    timer.current = setTimeout(
      () => {
        onDebounceEnd?.(value);
      },
      debounceTime,
    );
  };

  const getHelperText = () => {
    if (errorMessage) return errorMessage;
    if (maxCharacter) {
      const remainingLength = maxCharacter - inputValue.length;
      if (remainingLength > -1) {
        return `${remainingLength} characters remaining`;
      }
      return `${Math.abs(remainingLength)} characters over`;
    }
  };

  const hasError = () => {
    if (errorMessage) return true;
    if (maxCharacter) {
      return maxCharacter - inputValue.length < 0;
    }
    if (validations?.url) {
      return (
        !trackerValidation.default ||
        !trackerValidation.iframe ||
        !trackerValidation.script ||
        !trackerValidation.noscript ||
        !trackerValidation.img ||
        !trackerValidation.imgSrc
      );
    }
  };

  const textAreaDecoratorClass = isEnabled
    ? hasError()
      ? classes.textAreaError
      : classes.textAreaEnabled
    : `${baseClasses.textAreaDisabled} ${classes.textAreaDisabled}`;

  const textAreaVariantClass =
    variant === VARIANT_ENUM.outlined
    ? '' : baseClasses.textAreaStandard;

  const trackerValidationError = (
    <>
      {
        trackerValidation.script &&
        trackerValidation.noscript &&
        trackerValidation.iframe &&
        trackerValidation.img &&
        trackerValidation.imgSrc &&
        !trackerValidation.default &&
        <p className={baseClasses.errorText}>{ERRORS.default}</p>
      }
      {
        !trackerValidation.script &&
        <p className={baseClasses.errorText}>{ERRORS.script}</p>
      }
      {
        !trackerValidation.noscript &&
        <p className={baseClasses.errorText}>{ERRORS.noscript}</p>
      }
      {
        !trackerValidation.img &&
        <p className={baseClasses.errorText}>{ERRORS.img}</p>
      }
      {
        trackerValidation.img &&
        !trackerValidation.imgSrc &&
        <p className={baseClasses.errorText}>{ERRORS.imgSrc}</p>
      }
      {
        !trackerValidation.iframe &&
        <p className={baseClasses.errorText}>{ERRORS.iframe}</p>
      }
    </>
  );

  return (
    <>
      <TextField
        className={
          `${className}` +
          ` ${baseClasses.textArea}` +
          ` ${textAreaDecoratorClass}` +
          ` ${textAreaVariantClass}`
        }
        InputProps={{
          className: baseClasses.container,
        }}
        rows={rows}
        maxRows={rowsMax}
        multiline
        fullWidth={isFullWidth}
        style={style}
        label={label}
        placeholder={placeholder}
        disabled={!isEnabled}
        variant={variant}
        value={inputValue}
        onChange={onTextValueChange}
        error={hasError()}
        helperText={getHelperText()}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
      />
      {trackerValidationError}
    </>
  );
};

KrgTextArea.RESIZE_ENUM = RESIZE_ENUM;
KrgTextArea.VARIANT_ENUM = VARIANT_ENUM;
KrgTextArea.THEME_ENUM = THEME_ENUM;

export default KrgTextArea;
