import React, { useState, useMemo, useCallback } from "react";
import { useQueryClient } from "react-query";
import dayjs from "dayjs";
import { generatePath } from "react-router";
import { useHistory } from "react-router-dom";
import duration from "dayjs/plugin/duration";
import KrgDataTable from "@kargo/shared-components.krg-data-table";
import KrgChip from "@kargo/shared-components.krg-chip";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import KrgCheckbox from "@kargo/shared-components.krg-checkbox";
import KrgTooltip from "@kargo/shared-components.krg-tooltip";
import { TYPE_ENUM } from "@kargo/shared-components.krg-snackbar";
import ConfirmModal from "../Modals/ConfirmModal";
import { Video } from "../../api/types";
import { PATHS } from "../../constants/paths";
import useAPI from "../../api/useAPI";
import VideoModal from "../Modals/VideoModal";
import {
  DEFAULT_PAGINATION_LIMIT,
  DEFAULT_THUMBNAIL_URL,
} from "../../constants/constants";
import { formatDuration, isEnableEditDelete } from "../../helpers/videos";
import { Query } from "../../hooks/useVideos";
import { capitalizeStrings } from "../../helpers/capitilizeStrings";
import VideoActions from "./VideoActions";
import VideoSnackbar from "./VideoSnackbar";
import {
  ActionState,
  ActionType,
  SnackbarState,
} from "../../@types/media.types";

dayjs.extend(duration);

const useStyles = makeStyles({
  table: {
    "& thead th": {
      background: "transparent",
      color: "black",
      fontSize: "11px",
      fontWeight: 600,
    },
    "& tbody": {
      borderTop: "2px solid black",
      borderBottom: "2px solid black",
      "& tr:hover": {
        background: "#EEF3F7",
      },
    },
  },
});

const chipStyle = {
  margin: "2px",
  backgroundColor: "#e5eaef",
  padding: "7px 11px",
  border: "none",
};

interface VideosTableProps {
  videos: Video[];
  selectedVideos?: string[];
  onVideoToggle?: (video: string, isSelected: boolean) => void;
  onToggleAll?: (checked: boolean) => void;
  query?: Query;
}

const videoTagChips = (tags: string[]) => (
  <>
    {tags.map((tag) => (
      <KrgChip
        key={tag}
        style={chipStyle}
        theme={KrgChip.THEME_ENUM.v2}
        type={KrgChip.TYPE_ENUM.transparent}
        data={{ text: tag, id: tag }}
      />
    ))}
  </>
);

export default function VideosTable({
  videos,
  selectedVideos = [],
  onVideoToggle,
  onToggleAll,
  query,
}: VideosTableProps) {
  const history = useHistory();
  const classes = useStyles();
  const [modalVideoIndex, setModalVideoIndex] = useState(-1);
  const [actionState, setActionState] = useState<ActionState>({
    isOpen: false,
    type: "delete",
    video: null,
  });
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    type: TYPE_ENUM.success,
    isOpen: false,
    message: "",
  });

  const { api } = useAPI();
  const queryClient = useQueryClient();

  const isAllChecked = useMemo(() => {
    const selectedVideosSet = new Set(selectedVideos);
    return videos.every(
      (video) =>
        selectedVideosSet.has(video.token) || !isEnableEditDelete(video.state),
    );
  }, [videos, selectedVideos]);

  const modalVideo = videos[modalVideoIndex];
  const totalVideos = videos.length;

  const handleActionClick = (type: ActionType, video: Video) => {
    setActionState({ isOpen: true, type, video });
  };

  const handleConfirmAction = useCallback(async () => {
    if (actionState.video) {
      try {
        if (actionState.type === "delete") {
          await api.Media.remove(actionState.video.token);
        } else if (actionState.type === "restore") {
          await api.Media.update(actionState.video.token, actionState.video);
        }
        queryClient.invalidateQueries(["videos", query]);
        setSnackbarState({
          type: TYPE_ENUM.success,
          isOpen: true,
          message: `Successfully ${actionState.type}d video(s)`,
        });
      } catch (error) {
        setSnackbarState({
          type: TYPE_ENUM.success,
          isOpen: true,
          message: `Failed to ${actionState.type}d video(s)`,
        });
        console.error(`Failed to ${actionState.type} video:`, error);
      } finally {
        setActionState({ isOpen: false, type: actionState.type, video: null });
      }
    }
  }, [actionState, api.Media, queryClient, query]);

  const handleCloseSnackbar = () => {
    setSnackbarState({ ...snackbarState, isOpen: false });
  };

  const navigateToSingleVideo = (token: string) =>
    history.push(generatePath(PATHS.SINGLE_MEDIA_EDIT_PAGE, { token }));

  return (
    <>
      {/* Video Modal */}
      <VideoModal
        video={modalVideo?.token}
        isOpen={modalVideoIndex > -1}
        onClose={() => setModalVideoIndex(-1)}
        onNavigatePrev={() =>
          setModalVideoIndex((modalVideoIndex - 1 + totalVideos) % totalVideos)
        }
        onNavigateNext={() =>
          setModalVideoIndex((modalVideoIndex + 1) % totalVideos)
        }
      />

      {/* Confirm Modal */}
      <ConfirmModal
        isOpen={actionState.isOpen}
        onClose={() => setActionState({ ...actionState, isOpen: false })}
        onSubmitClick={handleConfirmAction}
        header={`${capitalizeStrings(actionState.type)} Video(s)`}
        body={`Are you sure you want to ${actionState.type} this video?`}
        submitButtonText={capitalizeStrings(actionState.type)}
        cancelButtonText="Cancel"
      />

      <Box sx={{ border: "1px solid #91A0AD" }}>
        {/* Data Table */}
        <KrgDataTable
          theme={KrgDataTable.THEME_ENUM.v2}
          className={classes.table}
          config={{
            pagination: {
              initialPageSize: DEFAULT_PAGINATION_LIMIT,
            },
            data: {
              columns: [
                {
                  type: KrgDataTable.CELL_TYPE_ENUM.checkbox,
                  headerName: "",
                  field: "isChecked",
                  renderHeader: () => (
                    <KrgCheckbox
                      theme={KrgCheckbox.THEME_ENUM.v2}
                      isChecked={isAllChecked}
                      onToggle={onToggleAll}
                    />
                  ),
                  renderCell: (row) => (
                    <KrgCheckbox
                      theme={KrgCheckbox.THEME_ENUM.v2}
                      isChecked={row.isChecked}
                      isEnabled={isEnableEditDelete(row.state)}
                      onToggle={(isChecked) =>
                        onVideoToggle?.(row.token, isChecked)
                      }
                    />
                  ),
                },
                {
                  headerName: "Thumbnail",
                  type: KrgDataTable.CELL_TYPE_ENUM.thumbnail,
                  field: "thumbnail",
                  renderCell: (row) => (
                    <img
                      src={row.thumbnail}
                      alt={row.title}
                      style={{ width: "70px", height: "40px" }}
                    />
                  ),
                  onClick: (_, row) => setModalVideoIndex(row.index),
                  width: "130px",
                },
                {
                  headerName: "Video Title",
                  type: KrgDataTable.CELL_TYPE_ENUM.subtitle,
                  field: "video_title",
                  onClick: (_, row) => navigateToSingleVideo(row.token),
                  width: "450px",
                },
                {
                  headerName: "Category",
                  field: "category",
                  width: "130px",
                  renderCell: (row: Video) => (
                    <>{capitalizeStrings(row.category, " ")}</>
                  ),
                },
                {
                  headerName: "Tags",
                  field: "tags",
                  width: "300px",
                  renderCell: (row) => {
                    const tags = row.tags.filter((tag) => tag.trim() !== "");
                    const [visibleTags, extraTags] = [
                      tags?.slice(0, 2),
                      tags?.slice(2),
                    ];

                    return (
                      <>
                        {videoTagChips(visibleTags)}
                        {extraTags.length > 0 && (
                          <KrgTooltip
                            theme={KrgTooltip.THEME_ENUM.v2}
                            tooltip={videoTagChips(extraTags)}
                            placement="top"
                            arrow
                          >
                            <KrgChip
                              style={chipStyle}
                              theme={KrgChip.THEME_ENUM.v2}
                              type={KrgChip.TYPE_ENUM.transparent}
                              data={{ text: "...", id: "extra" }}
                            />
                          </KrgTooltip>
                        )}
                      </>
                    );
                  },
                },
                {
                  headerName: "Publish Date",
                  field: "publish_date",
                  width: "120px",
                },
                {
                  headerName: "Duration",
                  field: "duration",
                  width: "120px",
                },
                {
                  headerName: "Status",
                  field: "state",
                  width: "120px",
                  renderCell: (row: Video) => (
                    <>{capitalizeStrings(row.state, "_")}</>
                  ),
                },
                {
                  headerName: "Actions",
                  field: "actions",
                  width: "120px",
                  renderCell: (row: Video) => (
                    <VideoActions
                      video={row}
                      onActionClick={handleActionClick}
                      onEditActionClick={() => navigateToSingleVideo(row.token)}
                    />
                  ),
                },
              ],
              rows: videos.map((video, index) => ({
                ...video,
                category: video.category,
                state: video.state,
                thumbnail:
                  video.default_stream?.image_url || DEFAULT_THUMBNAIL_URL,
                video_title: {
                  title: video.title,
                },
                publish_date: dayjs(video.created_at).format("MM/DD/YYYY"),
                duration: formatDuration(
                  video.local?.duration ||
                    video.media_info?.duration ||
                    video.default_stream?.media_info?.duration ||
                    0,
                ),
                isChecked: selectedVideos.includes(video.token),
                index,
              })),
            },
          }}
        />
      </Box>

      {/* Delete/Restore Notification */}
      <VideoSnackbar
        type={snackbarState.type}
        isOpen={snackbarState.isOpen}
        onClose={handleCloseSnackbar}
        message={snackbarState.message}
      />
    </>
  );
}
