import { makeStyles } from '@mui/styles';
import { FONT, COLORS } from '@kargo/shared-components.krg-shared';
import { Props } from './base-style';

const useStylesV1 = ({
  labelColor,
  borderColor,
  isAutoHeight,
}: Props) => makeStyles({
  textAreaEnabled: {
    // Border style
    '& .MuiInput-underline:before': {
      // Semi-transparent underline
      borderBottomColor: borderColor || COLORS.GRAY_DARK,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: borderColor || COLORS.GRAY_DARK,
      opacity: 0.4,
    },
    '& .MuiInput-underline:after': {
      // Solid underline on focus
      borderBottomColor: borderColor || COLORS.PRIMARY_500_ACCENT,
    },
    // Label style
    '& .MuiInputLabel-root': {
      color: labelColor || COLORS.BLACK_DISABLED,
    },
    '& .MuiInputLabel-root.MuiFormLabel-filled': {
      color: labelColor || COLORS.GRAY_DARK,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: labelColor || COLORS.PRIMARY_500_ACCENT,
    },
    // Border style for outline
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor:borderColor || COLORS.GRAY_DARK,
      },
      '&:hover fieldset': {
        borderColor: borderColor || COLORS.GRAY_DARK,
        opacity: 0.7,
      },
      '&.Mui-focused fieldset': {
        borderColor: borderColor || COLORS.PRIMARY_500_ACCENT,
      },
    },
  },
  textAreaDisabled: {},
  textAreaError: {
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.ERROR_DARK, // Solid underline on focus
    },
    '& .MuiFormHelperText-root': {
      color: COLORS.ERROR_DARK,
      fontSize: FONT.SIZE.MEDIUM,
      lineHeight: 1.4,
      overflow: isAutoHeight ? 'initial' : 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: isAutoHeight ? 'normal' : 'nowrap',
    },
    '& .MuiInputLabel-root': {
      color: COLORS.ERROR_DARK,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.ERROR_DARK,
      },
      '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.ERROR_DARK,
        opacity: 0.7,
      },
    },
  },
});

export default useStylesV1;
