import { isUrlValid } from "./validateUrl";
import { AtLeastOneLength } from "../@types/shared.types";
import { FormInputFields, FormValue } from "../@types/form.types";

/**
 * Check if a value is empty
 * @param value
 */
export const isEmptyValidation = (value: FormValue): boolean =>
  !value ||
  typeof value === "undefined" ||
  (Array.isArray(value) && value.length === 0) ||
  value.toString().trim().length === 0;

/**
 * Validate the length of a string
 * @param length
 * @param value
 * @param name
 */
export const LengthValidation = (
  length: AtLeastOneLength,
  value: FormValue,
  name?: string,
): boolean | string => {
  const { min, max } = length;
  const fieldName = name ? `field ${name}` : "this field";
  if (typeof min !== "undefined" && typeof max !== "undefined") {
    if (
      (typeof value === "string" || Array.isArray(value)) &&
      value.length < min &&
      value.length > max
    ) {
      return typeof value === "string"
        ? `Field ${name} could be only between ${min} and ${max} characters`
        : `Only between ${min} and ${max} items allowed for ${fieldName}`;
    }
    if (typeof value === "number" && value < min && value >= max) {
      return `Value for ${fieldName} should be between "${min}" and "${max}"`;
    }
  }
  if (typeof min !== "undefined") {
    if (
      (typeof value === "string" || Array.isArray(value)) &&
      value.length < min
    ) {
      return typeof value === "string"
        ? `Minimum ${min} characters required for ${fieldName}`
        : `Only ${min} items allowed for ${fieldName}`;
    }
    if (typeof value === "number" && value < min) {
      return `Value for ${fieldName} should be minimum: "${min}"`;
    }
  }
  if (typeof max !== "undefined") {
    if (
      (typeof value === "string" || Array.isArray(value)) &&
      value.length > max
    ) {
      return Array.isArray(value)
        ? `Maximum ${max} characters allowed for ${fieldName}`
        : `Only ${max} items allowed for ${fieldName}`;
    }
    if (typeof value === "number" && value > max) {
      return `Value for ${fieldName} should be minimum: "${min}"`;
    }
  }
  return true;
};

/**
 * Validate if a string is a valid email address
 * @param email
 */
export const isEmailValidation = (email: string): boolean | string => {
  if (isEmptyValidation(email)) return "Email can't be empty";
  // eslint-disable-next-line no-useless-escape
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return email.match(emailPattern) ? true : "Invalid email address";
};

/**
 * Validate if a string is a valid URL
 * @param url
 */
export const isValidURL = (url: string): boolean | string => {
  if (isEmptyValidation(url)) return "URL can't be empty";
  return isUrlValid(url) ? true : `Invalid URL field`;
};

/**
 * Will check form inputs for error existence based on provided rule(-s)
 * @param field
 * @param value
 */
export default (field: FormInputFields, value?: FormValue): string[] => {
  const errors: string[] = [];

  // If required field is empty
  if (field.required && isEmptyValidation(value)) {
    errors.push(`${field.label} is required field`);
  }

  if (typeof field.rules === "object") {
    const rules = Object.keys(field.rules);
    if (rules.length === 0) return errors;

    return [
      ...errors,
      ...rules
        .map((rule) => {
          switch (rule) {
            case "length": {
              if (
                typeof value === "string" ||
                typeof value === "number" ||
                Array.isArray(value)
              ) {
                const { min, max } = field.rules[rule];
                if (min || max) {
                  const lengthChecker = LengthValidation(
                    { min, max },
                    value,
                    field.label,
                  );
                  return typeof lengthChecker === "string" ? lengthChecker : "";
                }
              }
              break;
            }
            case "pattern": {
              if (typeof value !== "string")
                return `${field.name} has to be a string value`;
              return value.match(rule)
                ? ""
                : `${field.name} pattern in not correct, please be sure correct value provided`;
            }
            case "isURL": {
              if (typeof value !== "string")
                return `${field.name} has to be a string value`;
              return isValidURL(value)
                ? ""
                : `${field.name} in not url, please be sure correct value provided`;
            }
            default:
              break;
          }
          return "";
        })
        .filter((err) => typeof err === "string" && err.length > 0),
    ];
  }
  return errors;
};
