import { JSX } from "react";

export type SideBarProps = {
  isPublisherAdmin: boolean;
};

export type SidebarListItemButtonProps = {
  text: string;
  selected: boolean;
  path?: string;
  onClick?: () => void;
  iconElement?: JSX.Element;
};

export enum SidebarActionEnum {
  SET_MAIN_SIDEBAR = "SET_MAIN_SIDEBAR",
  SET_SINGLE_PAGE_SIDEBAR = "SET_SINGLE_PAGE_SIDEBAR",
}

export type SidebarActionTypeValue = SidebarActionEnum[keyof SidebarActionEnum];

export type SidebarState = {
  isOpen: boolean;
  singlePageSidebar?: [] | SidebarListItemButtonProps[];
};

export type SidebarDispatch = ({ type, payload }: SidebarActions) => void;

export type SidebarContext = [state: SidebarState, dispatch: SidebarDispatch];

export type SidebarActions = {
  type: SidebarActionTypeValue;
  payload?: SidebarState | undefined;
};
