import React, { ListItemIcon } from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

const SidebarBackIconItem = () => (
  <ListItemIcon sx={{ minWidth: "30px" }}>
    <ArrowBackIos sx={{ fontSize: "15px" }} color="primary" />
  </ListItemIcon>
);

export default SidebarBackIconItem;
