import { ChipData } from "@kargo/shared-components.krg-chip/dist/shared/interfaces";
import {
  ActionOnFieldEnum,
  FormContextState,
  FormInputFields,
  FormValue,
} from "../@types/form.types";
import formRuleChecker from "./formRules";

const dataTransfer = (
  value: FormValue,
  field: FormInputFields,
  action?: ActionOnFieldEnum,
): {
  formValue: FormValue;
  dataValue: FormValue;
} => {
  if (field.type === "chip") {
    if (action === ActionOnFieldEnum.ADD) {
      if (typeof value === "object" && (value as ChipData).text) {
        return {
          formValue: [
            ...(field.value as ChipData[]),
            {
              id: (value as ChipData).text,
              text: (value as ChipData).text,
            },
          ],
          dataValue: [
            ...(field.value as ChipData[]).map((v) => v.text),
            (value as ChipData).text,
          ],
        };
      }
      if (Array.isArray(value)) {
        const values = value.map((v) => ({ text: v, id: v }));
        return {
          formValue: [
            ...(field.value as ChipData[]),
            ...(values.length > 0 && value.map((v) => ({ text: v, id: v }))),
          ],
          dataValue: [
            ...(field.value as ChipData[]).map((v) => v.text),
            ...(values.length > 0 && values.map((v) => v.text)),
          ],
        };
      }
    }
    if (action === ActionOnFieldEnum.REMOVE) {
      return {
        formValue: [
          ...(field.value as ChipData[]).filter(
            (v: ChipData) => v.text !== (value as ChipData).text,
          ),
        ],
        dataValue: (field.value as ChipData[])
          .filter((v: ChipData) => v.text !== (value as ChipData).text)
          .map((v: ChipData) => v.text),
      };
    }
  }
  if (field.name === "category") {
    return {
      formValue: value,
      dataValue: (value as string[]).join(", "),
    };
  }
  return {
    formValue: value,
    dataValue: value,
  };
};

export default (
  state: FormContextState,
  payload: {
    field: FormInputFields;
    value: FormValue;
  },
  action?: ActionOnFieldEnum,
  touched: boolean = true,
): FormContextState => {
  let errorMessages: string[] = [];
  const { field, value } = payload;
  const { name, errorMessage } = field;

  // reset errors and data. Avoid JSLinter error: "no-param-reassign"
  const data = { ...state.data };
  if (data[name]) delete data[name];
  const errors = { ...state.errors };
  if (errors[name]) delete errors[name];

  // check validation
  const validationResults = formRuleChecker(field, value);
  if (validationResults.length > 0 || errorMessage) {
    errorMessages =
      errorMessage && errorMessage.length > 0
        ? [errorMessage]
        : validationResults;
  }
  const { formValue, dataValue } = dataTransfer(value, field, action);

  return {
    ...state,
    formInputFields: {
      ...state.formInputFields,
      [name]: {
        ...payload.field,
        touched,
        value: formValue,
        error: errorMessages,
      },
    },
    ...(validationResults.length > 0 && {
      data,
      errors: { ...state.errors, [name]: errorMessages },
    }),
    ...(validationResults.length === 0 && {
      errors,
      data: { ...data, [name]: dataValue },
    }),
  };
};
