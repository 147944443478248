import React, {
  createContext, useContext, useReducer, useMemo, JSX, Reducer, Context, Dispatch
} from "react";

import { SidebarActions, SidebarState, SidebarContext } from "../../@types/sidebar.types";
import sidebarReducer from "./sidebar.reducer";

const initialState: SidebarState = {
  isOpen: true,
  singlePageSidebar: [],
}

const CurrentSidebarContext: Context<SidebarContext> = createContext(null!);

export const SidebarProvider = ({ children }: {
  children: JSX.Element
}) => {
    const [store, dispatch] = useReducer<Reducer<SidebarState, SidebarActions>>(
      sidebarReducer, {...initialState}
    );

    const contextMemorizedValue: [SidebarState, Dispatch<SidebarActions>]  = useMemo(() => [
      store, dispatch
    ], [store]);

    return (
      <CurrentSidebarContext.Provider value={contextMemorizedValue}>
          {children}
      </CurrentSidebarContext.Provider>
    )
}

export const useSidebarContext = (): SidebarContext => {
  const context = useContext<SidebarContext>(CurrentSidebarContext);
  if (!context) {
    throw new Error('useSidebarContext must be used within a SidebarProvider');
  }
  return context;
}
