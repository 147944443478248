import React from "react";
import AutoComplete from "../AutoComplete/AutoComplete";

type Props = {
  asAdmin?: boolean;
  isEnabled?: boolean;
  value?: string[];
  onChange?: (cat: string[]) => void;
};

export default function TagSelect({
  isEnabled = true,
  value = [],
  onChange,
}: Props) {
  const tags = [];

  return (
    <AutoComplete
      id="tags-select"
      isEnabled={isEnabled}
      label="Tags"
      placeholder="Add"
      value={value}
      onChange={onChange}
      options={tags.map((tag, id) => ({ id, name: tag }))}
    />
  );
}
