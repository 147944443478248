import {
  ActionOnFieldEnum,
  FormActions,
  FormActionsEnum,
  FormContextState,
  FormInitInputs,
} from "../../@types/form.types";

import formStateNormalizer from "../../helpers/formStateNormalizer";

const GENERIC_FORM_ERROR_KEY: string = "formError";

export default (
  state: FormContextState,
  action: FormActions,
): FormContextState => {
  switch (action.type) {
    case FormActionsEnum.RESET_FORM:
      return {
        ...state,
        formInputFields: action.payload as FormInitInputs,
        errors: {},
        touched: false,
      };

    case FormActionsEnum.CHANGE_FORM_FIELD:
      return formStateNormalizer(state, action.payload);
    case FormActionsEnum.VALIDATE_FORM_FIELD:
      return formStateNormalizer(state, action.payload, null, false);
    case FormActionsEnum.ADD_TAG:
      return formStateNormalizer(state, action.payload, ActionOnFieldEnum.ADD);
    case FormActionsEnum.REMOVE_TAG:
      return formStateNormalizer(
        state,
        action.payload,
        ActionOnFieldEnum.REMOVE,
      );

    case FormActionsEnum.SET_FORM_TOUCHED:
      return { ...state, touched: true };
    case FormActionsEnum.SET_FORM_SUBMITTING:
      return { ...state, submitting: true };
    case FormActionsEnum.SET_FORM_SUBMITTED:
      return { ...state, submitting: false };

    // doing the same but was split to be more clear
    case FormActionsEnum.SET_FORM_SUBMIT_ERROR:
    case FormActionsEnum.SET_FORM_ERROR: {
      const errors = { ...state.errors };
      if (errors[GENERIC_FORM_ERROR_KEY]) delete errors[GENERIC_FORM_ERROR_KEY];
      return {
        ...state,
        showAllErrors: true,
        submitting: false,
        errors: {
          ...(action.payload && {
            [GENERIC_FORM_ERROR_KEY]: [action.payload as string],
          }),
          ...state.errors,
        },
      };
    }

    case FormActionsEnum.CLOSE_FORM_ERROR: {
      const errors = { ...state.errors };
      if (errors[GENERIC_FORM_ERROR_KEY]) delete errors[GENERIC_FORM_ERROR_KEY];
      // will show one error for
      return { ...state, showAllErrors: false, errors };
    }
    default:
      return state;
  }
};
