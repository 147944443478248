import React, { ComponentProps } from "react";
import { Box, Divider, SxProps } from "@mui/material";
import KrgTabs from "@kargo/shared-components.krg-tabs";
import {
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
  SIZE_ENUM as BUTTON_SIZE_ENUM,
} from "@kargo/shared-components.krg-button";
import { makeStyles } from "@mui/styles";
import PaginatedTabs from "../PaginatedTabs";
import TrackedKrgButton from "../TrackedButton/TrackedKrgButton";

const useStyles = makeStyles({
  button: {
    "&.Mui-disabled": {
      "&>div>div": {
        color: "#91A0AD",
      },
    },
    "&>div>div": {
      textTransform: "initial",
      padding: "0",
    },
  },
});

type Props = {
  buttonsConfig: ComponentProps<typeof TrackedKrgButton>[];
  tabsConfig: ComponentProps<typeof KrgTabs>;
  sx?: SxProps;
};

export default function SectionNavBar({
  buttonsConfig,
  tabsConfig,
  sx,
  children,
}: React.PropsWithChildren<Props>) {
  const classes = useStyles();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ...sx,
        }}
        className="SectionNavBar"
      >
        <PaginatedTabs tabsConfig={tabsConfig} />
        <Box display="flex" flexWrap="nowrap">
          {buttonsConfig.map((config) => (
            <TrackedKrgButton
              id={config.id}
              style={{
                padding: "7px 28px",
                minWidth: "120px",
                marginLeft: "15px",
                whiteSpace: "nowrap",
              }}
              size={BUTTON_SIZE_ENUM.small}
              variant={BUTTON_VARIANT_ENUM.contained}
              {...config}
              key={config.id}
              className={`${classes.button} ${config.className}`}
            />
          ))}
          {children}
        </Box>
      </Box>
      <Divider
        variant="fullWidth"
        sx={{
          mt: "-1px",
          mb: "32px",
          borderBottomWidth: "1px",
          borderColor: "transparent",
        }}
      />
    </>
  );
}
