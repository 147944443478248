import React, { IconButton } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import KrgTooltip from "@kargo/shared-components.krg-tooltip";

const InfoPopover = ({
  text,
  size = "small",
  color = "black",
}: {
  text: string;
  size?: "small" | "medium" | "large";
  color?: string;
}) => (
  <KrgTooltip tooltip={text}>
    <IconButton
      sx={{
        padding: "0 4px",
      }}
      size={size}
    >
      <InfoOutlined fill={color} fontSize={size} />
    </IconButton>
  </KrgTooltip>
);

export default InfoPopover;
