export default {
  business: "business",
  celebrity: "celebrity",
  education: "education",
  entertainment: "entertainment",
  fashion: "fashion",
  finance: "finance",
  lifestyle: "lifestyle",
  news: 'news',
  pets: "pets",
  politics: "law, govt and politics",
  science: "science",
  shareable: "shareable",
  shopping: "shopping",
  society: "society",
  sports: "sports",
  technology: "technology and computing",
  travel: 'travel',
  "art and entertainment": "art and entertainment",
  "automotive and vehicles": "automotive and vehicles",
  "Business and Industrial": "business and industrial",
  "family and parenting": "family and parenting",
  "food and drink": "Food and Drink",
  "health and fitness": "health and fitness",
  "hobbies & leisure": "hobbies & leisure",
  "home & garden": "home and garden",
  "style and fashion": "style and fashion",
};
